import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useTranslation } from "react-i18next";
import config from "../../../config";

export const LegalCompliance = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        variant="body2"
        dangerouslySetInnerHTML={{
          __html: t("auth.legal-links.legal-consent", {
            links: config.legalLinks,
            interpolation: { escapeValue: false }
          })
        }}
      ></Typography>
    </Box>
  );
};
