import Axios from "axios";
import i18n from "i18next";
import { createSelector } from "reselect";
import { apiURL } from "../config";

////////////////////////////////////////////////////////
//// ACTIONS
////////////////////////////////////////////////////////

export const ACTION_SET_LANGUAGE = "ACTION_SET_LANGUAGE";

export const setLanguage = lang => {
  return {
    type: ACTION_SET_LANGUAGE,
    lang: lang
  };
};

export const determineLanguage = () => {
  return async (dispatch, getState) => {
    if (!getState().language.determined) {
      // update language in i18n, session storage and redux store
      const updateLang = lang => {
        console.log("datermineLanguage#updateLang", lang);
        i18n.changeLanguage(lang);
        sessionStorage.setItem("b2jlang", lang);
        dispatch(setLanguage(lang));
      };

      // check if lang in url param
      const pLang = new URL(window.location.href).searchParams.get("lang");
      if (pLang) {
        updateLang(pLang);
        return;
      }

      // language already in session storage?
      let lang = sessionStorage.getItem("b2jlang");
      if (lang == null) {
        // if no => determine via server call
        Axios.get(apiURL("/i18n")).then(res => {
          updateLang(res.data);
        });
      } else {
        // update lang with lang from storage
        updateLang(lang);
      }
    }
  };
};

////////////////////////////////////////////////////////
//// REDUCER
////////////////////////////////////////////////////////
const initialState = {
  current: "de",
  determined: false
};

export const i18nReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_SET_LANGUAGE:
      return {
        ...state,
        current: action.lang,
        determined: true
      };
    default:
      return state;
  }
};

////////////////////////////////////////////////////////
//// SELECTOR
////////////////////////////////////////////////////////
export const selectLanguage = () => {
  return createSelector(
    state => state.language,
    language => language
  );
};
