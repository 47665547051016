import React from "react";
import { Redirect } from "react-router-dom";
import { PrivateRoute } from "../../auth/components/private-route";
import config from "../../config";
import { PUIWatchList } from "../../power-ui/pages";

const Routes = () => (
  <>
    <PrivateRoute path="/pui/watch-list" component={PUIWatchList} />
    <PrivateRoute
      path="/pui"
      exact
      component={() => <Redirect to={config.defaultPUIRoute} />}
    />
  </>
);

export { Routes as PUIRoutes };
