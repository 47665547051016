import { createSelector } from "reselect";
import jwt_decode from "jwt-decode";

// isAuthenticated returns true if we are authenticated
export const isAuthenticated = level => {
  return createSelector(
    state => state.auth,
    auth => {
      if (!auth.token) {
        return false;
      }

      const dec = jwt_decode(auth.token);
      return (dec.lvl & level) === level;
    }
  );
};

// currentIdentity returns our own identity or null if available
export const currentIdentity = () => {
  return createSelector(
    state => state.auth,
    auth => auth.self
  );
};

export const authenticatedInSession = () => {
  return createSelector(
    state => state.auth,
    auth => auth.authenticatedInSession
  );
};

export const hasRole = role => {
  return createSelector(
    state => state.auth,
    auth =>
      auth &&
      auth.self &&
      auth.self.roles &&
      auth.self.roles.find(x => x === role) > -1
  );
};

/**
 * Select preference of currently loaded identity
 */
export const identityPref = (key, def = "") => {
  return createSelector(
    state => state.auth,
    auth => {
      if (auth && auth.self && !!auth.self.prefs[key]) {
        return auth.self.prefs[key];
      }

      return def;
    }
  );
};
