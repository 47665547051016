import { Grid, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RoleGlobalPro } from "../../auth/const";
import { hasRole } from "../../auth/selectors";
import config from "../../config";
import { useAllAvailableJCAs } from "../../domain/hooks";
import { PAppBar } from "../../power-pro-shared/p-app-bar";
import { useStyles } from "../../theme/classes";
import { ProJobList } from "../components/pro-job-list";
import { ProMarketplaceInfoAlert } from "../components/pro-marketplace-info-alert";
import { useMarketplaceJobs } from "../hooks";
import { selectJCs } from "../selectors";

const ProMarketplace = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const hasRequiredRole = useSelector(hasRole(RoleGlobalPro));
  const history = useHistory();

  // redirect to PUI on insufficient permissions
  useEffect(() => {
    if (hasRequiredRole === false) {
      history.push(config.defaultPUIRoute);
    }
  }, [hasRequiredRole, history]);

  // download all available JCAs
  useMarketplaceJobs();
  useAllAvailableJCAs();

  // select available JCAs
  const jcs = useSelector(selectJCs());

  return (
    <>
      <Helmet>
        <title>{t("power-ui.page.title")}</title>
      </Helmet>
      <PAppBar />

      <Grid
        className={classes.belowAppBar}
        container
        spacing={2}
        direction="column"
      >
        <Grid item>
          <Typography variant="h4" style={{ color: "#c0c0c0" }}>
            {t("pro-ui.pro-marketplace.title")}
          </Typography>
        </Grid>
        <Grid item>
          <ProMarketplaceInfoAlert />
        </Grid>
        <Grid item>
          <ProJobList jcs={jcs} />
        </Grid>
      </Grid>
    </>
  );
};

export default ProMarketplace;
