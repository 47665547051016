//setup initial states
import { ACTION_PROUI_STORE_MARKETPLACE_JOBS } from "../actions";

const initialState = {
  jcs: []
};

const proReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_PROUI_STORE_MARKETPLACE_JOBS:
      return {
        ...state,
        jcs: action.jcs
      };
    default:
      return state;
  }
};

export default proReducer;
