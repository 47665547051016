import React from "react";
import { useTranslation } from "react-i18next";
import { StateLegalLinks } from "./state-legal-links";
import { WizardTitle } from "./wizard-title";

export const StateOTPLimitExceeded = ({ transitState, sharedState }) => {
  const { t } = useTranslation();

  return (
    <>
      <div data-testid="state-top-limit-exceeded">
        <WizardTitle
          title={t("auth.otp-limit-exceeded.title")}
          text={t("auth.otp-limit-exceeded.sub-title")}
        />
      </div>

      <StateLegalLinks />
    </>
  );
};
