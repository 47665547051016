//setup initial states
import {
  ACTION_DOMAIN_STORE_JCA,
  ACTION_DOMAIN_STORE_JCAS,
  ACTION_DOMAIN_UPDATE_JCA_ARCHIVED,
  ACTION_UPDATE_JCAS_FILTER,
  SORT_TITLE
} from "./actions";

const initialState = {
  jcas: [],
  jcasFilter: {
    textSearch: "",
    sorting: SORT_TITLE,
    companyId: -1
  }
};

export const domain = (state = initialState, action) => {
  const sortedJCAs = jobs => jobs;
  switch (action.type) {
    case ACTION_DOMAIN_STORE_JCA:
      const existingItem = state.jcas.find(
        jca => jca.job.id === action.jca.job.id
      );

      if (
        existingItem &&
        existingItem.association.customToken &&
        !action.jca.association.customToken
      ) {
        // be sure that we do not override JCA with customToken with JCA without customToken
        console.log(
          "ACTION_DOMAIN_STORE_JCA: existingItem has customToken - new one not. ignoring"
        );
        return state;
      }

      return {
        ...state,
        jcas: sortedJCAs([
          ...state.jcas.filter(jca => jca.job.id !== action.jca.job.id),
          action.jca
        ])
      };
    case ACTION_DOMAIN_STORE_JCAS:
      return {
        ...state,
        jcas: sortedJCAs([
          ...state.jcas.filter(
            jca =>
              action.jcas.findIndex(jca2 => jca2.job.id === jca.job.id) === -1
          ),
          ...action.jcas
        ])
      };
    case ACTION_DOMAIN_UPDATE_JCA_ARCHIVED:
      return {
        ...state,
        jcas: state.jcas.map(jca =>
          jca.job.id === action.jobId
            ? {
                ...jca,
                association: {
                  ...jca.association,
                  node: { ...jca.association.node, archived: true }
                }
              }
            : jca
        )
      };
    case ACTION_UPDATE_JCAS_FILTER:
      return {
        ...state,
        jcasFilter: action.filter
      };
    default:
      return state;
  }
};
