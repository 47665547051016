import { createMuiTheme } from "@material-ui/core/styles";

const B2JTheme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "rgb(3,98,159)",
      contrastText: "#fff"
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#BDBDBD",
      main: "#00a0b2",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffffff"
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2
  },
  typography: {
    h6: {
      lineHeight: "1.1"
    },
    button: {
      textTransform: "none"
    }
  }
});

export default B2JTheme;
