import {
  ACTION_ERROR_OCCURED,
  ACTION_ERROR_RESOLVED,
  ACTION_ERROR_HIDE
} from "./actions";

const initialState = {
  loading: false,
  entityType: null
};

export default function error(state = initialState, action) {
  switch (action.type) {
    case ACTION_ERROR_OCCURED:
      return {
        present: true,
        show: true,
        errorType: action.errorType,
        status: action.status ? action.status : 0,
        message: action.message ? action.message : "",
        i18n: action.i18n
      };
    case ACTION_ERROR_RESOLVED:
      return {
        present: false
      };
    case ACTION_ERROR_HIDE:
      return {
        ...state,
        show: false
      };
    default:
      return state;
  }
}
