import { Grid } from "@material-ui/core";
import React from "react";
import { PUIJobCard } from "../pui-job-card";

const PUIJobList = ({ jcas }) => {
  console.log("PUIJobList @jcas = ", jcas);
  return (
    <>
      <Grid container spacing={2} direction="column">
        {jcas.map(jca => (
          <Grid key={"" + jca.job.id} item>
            <PUIJobCard jca={jca} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default PUIJobList;
