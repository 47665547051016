import { IconButton, InputAdornment } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { apiURL } from "../../../config";
import { defaultErrorBehaviour } from "../../../generics/errors/actions";
import { errorMessage } from "../../../generics/errors/helpers";
import { setDisableBackgropClickHint } from "../../../generics/loading/actions";
import * as validators from "../../../generics/validators";
import * as actions from "../../actions";
import {
  ErrAuthenticationFailed,
  ErrLimitExceeded,
  ErrOTPNA
} from "../../errors";
import { hashPassword } from "../../helpers";
import { useStyles } from "../styles";
import {
  STATE_DETAILS,
  STATE_OTP_LIMIT_EXCEEDED,
  STATE_OTP_SCENARIO_INITIAL_PWD,
  STATE_OTP_SCENARIO_RESET_PWD,
  STATE_START
} from "./constants";
import { StateLegalLinks } from "./state-legal-links";
import { WizardTitle } from "./wizard-title";

export const StateOTP = ({ transitState, sharedState }) => {
  // DTO for registration
  const initialDTO = {
    email: sharedState.email,
    otp: "",
    password: ""
  };

  const [dto, setDTO] = useState(initialDTO);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const scenario = useMemo(() => sharedState.otpScenario, [sharedState]);

  // disable backgrop clicks while in OTP. See MS-153
  useEffect(() => {
    dispatch(setDisableBackgropClickHint(true));
    return () => {
      dispatch(setDisableBackgropClickHint(false));
    };
  }, [dispatch]);

  const [errors, setErrors] = useState(
    validators.deriveNoErrorsObj(initialDTO)
  );
  const [wrongOTP, setWrongOTP] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  // requestPasswordReset will trigger an OTP to be sent
  const requestPasswordReset = useCallback(() => {
    const a = async () => {
      try {
        setLoading(true);
        await axios.put(apiURL("/identity/_/password"), {
          email: sharedState.email
        });
      } catch (e) {
        dispatch(defaultErrorBehaviour(e));
      } finally {
        setLoading(false);
      }
    };
    a();
  }, [dispatch, sharedState]);

  // initialle request password reset OTP
  useEffect(() => {
    requestPasswordReset();
  }, [dispatch, requestPasswordReset]);

  // submit form and process result
  const submit = async event => {
    // everything valid - create identity!
    try {
      setLoading(true);
      const tokenResponse = await axios.patch(apiURL("/auth/otp"), {
        ...dto,
        password: hashPassword(dto.password)
      });

      // let's tell the world
      dispatch(actions.fireAuthenticated(tokenResponse.data.token));

      // remember that user confirmed his identity in this session
      dispatch(actions.flagAuthenticatedInSession());

      // continue to finished state
      transitState(STATE_DETAILS);
    } catch (error) {
      switch (errorMessage(error)) {
        case ErrAuthenticationFailed:
          setWrongOTP(true);
          break;
        case ErrOTPNA:
          dispatch(defaultErrorBehaviour(error));
          transitState(STATE_START);
          break;
        case ErrLimitExceeded:
          dispatch(defaultErrorBehaviour(error));
          transitState(STATE_OTP_LIMIT_EXCEEDED);
          break;
        default:
          dispatch(defaultErrorBehaviour(error));
          break;
      }
    } finally {
      setLoading(false);
    }
  };

  // handleForm validation etc. calls submit when there are no errors
  const handleForm = async event => {
    event.preventDefault();
    event.stopPropagation();

    const errs = {
      otp: validators.isEmpty(dto.otp),
      password: dto.password.length < 5
    };
    setErrors(errs);

    if (validators.hasNoError(errs)) {
      submit();
    }
  };

  const updateDTO = value => {
    setDTO({ ...dto, otp: value });
    setWrongOTP(false);
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <>
      <div data-testid="state-otp">
        {scenario === STATE_OTP_SCENARIO_RESET_PWD && (
          <WizardTitle
            title={t("auth.otp.password-reset.title")}
            text={t("auth.otp.password-reset.description")}
          />
        )}
        {scenario === STATE_OTP_SCENARIO_INITIAL_PWD && (
          <WizardTitle
            title={t("auth.otp.password-initial.title")}
            text={t("auth.otp.password-initial.description")}
          />
        )}

        <form className={classes.form} onSubmit={handleForm} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                type="email"
                disabled={true}
                variant="outlined"
                required
                fullWidth
                id="email"
                label={t("generic.word.email")}
                name="email"
                value={sharedState.email}
                inputProps={{
                  "data-testid": "input-email"
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                type="text"
                error={errors.otp}
                disabled={loading}
                variant="outlined"
                required
                fullWidth
                id="otp"
                label={t("generic.word.otp")}
                name="otp"
                autoComplete="otp"
                inputProps={{
                  "data-testid": "input-otp"
                }}
                helperText={errors.otp && t("auth.otp.validation.otp")}
                onChange={e => updateDTO(e.target.value)}
              />
              {wrongOTP && (
                <Alert severity="warning">
                  {t("auth.otp.validation.wrong")}
                </Alert>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                type={showPassword ? "text" : "password"}
                error={errors.password}
                disabled={loading}
                variant="outlined"
                required
                fullWidth
                id="password"
                label={t("generic.word.password-new")}
                name="password"
                autoComplete="password"
                inputProps={{
                  "data-testid": "input-password"
                }}
                helperText={
                  errors.password && t("auth.otp.validation.password")
                }
                onChange={e => setDTO({ ...dto, password: e.target.value })}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                disabled={loading}
                fullWidth
                color="primary"
                data-testid="button-resetpwd"
                className={classes.altSubmit}
                onClick={() => {
                  transitState(STATE_START);
                }}
              >
                {t("generic.word.back")}
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button
                disabled={loading}
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                data-testid="input-submit"
              >
                {t("generic.word.next")}
              </Button>
            </Grid>
          </Grid>
        </form>

        <StateLegalLinks />
      </div>
    </>
  );
};
