import React from "react";
import { Redirect } from "react-router-dom";
import { PrivateRoute } from "../../auth/components/private-route";
import config from "../../config";
import { ProMarketplace, ProWatchList } from "../../pro-ui/pages";

const Routes = () => (
  <>
    <PrivateRoute path="/pro/market" component={ProMarketplace} />
    <PrivateRoute path="/pro/watch-list" component={ProWatchList} />
    <PrivateRoute
      path="/pro"
      exact
      component={() => <Redirect to={config.defaultProRoute} />}
    />
  </>
);

export { Routes as ProRoutes };
