import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { AuthLevel2 } from "../auth/const";
import { isAuthenticated } from "../auth/selectors";
import config, { apiURL } from "../config";
import { defaultErrorBehaviour } from "../generics/errors/actions";
import { flashMessage } from "../generics/flash";
import * as validators from "../generics/validators";
import { useStyles } from "../theme/classes";
import { initialContainerDTO, OrderContext } from "./state";

const useCompany = () => {
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState(null);
  const authenticated = useSelector(isAuthenticated(AuthLevel2));

  useEffect(() => {
    const fetchData = async () => {
      const url = apiURL("/companies");
      try {
        const res = await axios.get(url);
        if (res.data && Array.isArray(res.data) && res.data.length > 0) {
          setCompany(res.data[0]);
        }
      } catch (e) {
        console.error("useCompany", e);
      } finally {
        setLoading(false);
      }
    };

    if (authenticated) {
      fetchData();
    }
  }, [authenticated]);

  return { loading, company };
};

const InnerPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { company, setCompany, container } = useContext(OrderContext);
  console.log("!!!!!! InnerPage", container);
  const containerPrice = useMemo(() => container.findersFee * 0.15, [
    container
  ]);
  const existingCompany = useCompany();
  const [creating, setCreating] = useState(false);
  const loading = useMemo(() => creating || existingCompany.loading, [
    existingCompany,
    creating
  ]);
  const disabled = useMemo(() => loading || existingCompany.company !== null, [
    loading,
    existingCompany
  ]);
  const history = useHistory();

  const [errors, setErrors] = useState(
    validators.deriveNoErrorsObj(initialContainerDTO)
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (existingCompany.loading && existingCompany.company !== null) {
      setCompany(existingCompany.company);
    }
  }, [existingCompany, setCompany]);

  // submit form and process result
  const transitNext = async () => {
    try {
      setCreating(true);

      let companyId = -1;

      // create company?
      if (existingCompany.company === null) {
        // ====> YES!
        const companyRes = await axios.post(apiURL("/companies"), company);
        companyId = companyRes.data.id;
      } else {
        companyId = existingCompany.company.id;
      }

      // create job
      const jobRes = await axios.post(apiURL("/jobs"), {
        ...container,
        companyId: companyId
      });

      // join job
      const joinRes = await axios.post(apiURL("/rt-id/" + jobRes.data.id));
      dispatch(flashMessage("order.flash.job-created", "success"));
      history.push(`/${joinRes.data.association.customToken.token}`);
    } catch (e) {
      dispatch(defaultErrorBehaviour(e));
    } finally {
      setCreating(false);
    }
  };

  // handleForm validation etc. calls submit when there are no errors
  const handleForm = async event => {
    event.preventDefault();
    event.stopPropagation();

    const errs = {
      companyName: validators.isEmpty(company.companyName),
      street: validators.isEmpty(company.street),
      zip: validators.isEmpty(company.zip),
      city: validators.isEmpty(company.city)
    };
    setErrors(errs);

    if (validators.hasNoError(errs)) {
      transitNext();
    }
  };

  const handleBack = () => {
    history.push("/order");
  };

  const cls = {
    iptHeader: "h6",
    iptSubHeader: "body1",
    hSpacing: 3,
    iptSpacing: 1
  };

  return (
    <>
      <div data-testid="state-view">
        <form className={classes.form} onSubmit={handleForm} noValidate>
          <Card className={classes.detailsCard}>
            <CardContent>
              <Typography gutterBottom variant="h5">
                {t("order.provider-details.details.header")}
              </Typography>
              <Typography variant="body1">
                {t("order.provider-details.details.subHeader")}
              </Typography>

              {disabled && (
                <Box pt={cls.hSpacing}>
                  <Alert severity="info">
                    {t("order.provider-details.immutable")}
                  </Alert>
                </Box>
              )}

              {/*
               * FIELD
               */}
              <Box pt={cls.hSpacing}>
                <Typography gutterBottom variant={cls.iptHeader}>
                  {t("order.provider-details.details.companyName.header")}
                </Typography>
                <Typography gutterBottom variant={cls.iptSubHeader}>
                  {t("order.provider-details.details.companyName.subHeader")}
                </Typography>
              </Box>
              <Box mt={cls.iptSpacing}>
                <TextField
                  error={errors.companyName}
                  disabled={disabled}
                  variant="outlined"
                  required
                  fullWidth
                  id="companyName"
                  label={t("order.provider-details.details.companyName.label")}
                  name="companyName"
                  helperText={
                    errors.companyName &&
                    t("generic.validation.string-required")
                  }
                  value={company.companyName}
                  onChange={e =>
                    setCompany({ ...company, companyName: e.target.value })
                  }
                />
              </Box>

              {/*
               * FIELD
               */}
              <Box pt={cls.hSpacing}>
                <Typography gutterBottom variant={cls.iptHeader}>
                  {t("order.provider-details.details.street.header")}
                </Typography>
                <Typography gutterBottom variant={cls.iptSubHeader}>
                  {t("order.provider-details.details.street.subHeader")}
                </Typography>
              </Box>
              <Box mt={cls.iptSpacing}>
                <TextField
                  error={errors.street}
                  disabled={disabled}
                  variant="outlined"
                  required
                  fullWidth
                  id="street"
                  label={t("order.provider-details.details.street.label")}
                  name="street"
                  helperText={
                    errors.street && t("generic.validation.string-required")
                  }
                  value={company.street}
                  onChange={e =>
                    setCompany({ ...company, street: e.target.value })
                  }
                />
              </Box>

              {/*
               * FIELD
               */}
              <Box pt={cls.hSpacing}>
                <Typography gutterBottom variant={cls.iptHeader}>
                  {t("order.provider-details.details.zip.header")}
                </Typography>
                <Typography gutterBottom variant={cls.iptSubHeader}>
                  {t("order.provider-details.details.zip.subHeader")}
                </Typography>
              </Box>
              <Box mt={cls.iptSpacing}>
                <TextField
                  error={errors.zip}
                  disabled={disabled}
                  variant="outlined"
                  required
                  fullWidth
                  id="zip"
                  label={t("order.provider-details.details.zip.label")}
                  name="zip"
                  helperText={
                    errors.zip && t("generic.validation.string-required")
                  }
                  value={company.zip}
                  onChange={e =>
                    setCompany({ ...company, zip: e.target.value })
                  }
                />
              </Box>

              {/*
               * FIELD
               */}
              <Box pt={cls.hSpacing}>
                <Typography gutterBottom variant={cls.iptHeader}>
                  {t("order.provider-details.details.city.header")}
                </Typography>
                <Typography gutterBottom variant={cls.iptSubHeader}>
                  {t("order.provider-details.details.city.subHeader")}
                </Typography>
              </Box>
              <Box mt={cls.iptSpacing}>
                <TextField
                  error={errors.city}
                  disabled={disabled}
                  variant="outlined"
                  required
                  fullWidth
                  id="city"
                  label={t("order.provider-details.details.city.label")}
                  name="city"
                  helperText={
                    errors.city && t("generic.validation.string-required")
                  }
                  value={company.city}
                  onChange={e =>
                    setCompany({ ...company, city: e.target.value })
                  }
                />
              </Box>

              {/*
               * FIELD
               */}
              <Box pt={cls.hSpacing}>
                <Typography gutterBottom variant={cls.iptHeader}>
                  {t("order.provider-details.details.country.header")}
                </Typography>
                <Typography gutterBottom variant={cls.iptSubHeader}>
                  {t("order.provider-details.details.country.subHeader")}
                </Typography>
              </Box>
              <Box mt={cls.iptSpacing}>
                <FormControl variant="outlined" required fullWidth>
                  <Select
                    disabled={disabled}
                    value={company.country}
                    onChange={e =>
                      setCompany({ ...company, country: e.target.value })
                    }
                  >
                    {config.supportedCountriesFavorites.map(v => (
                      <MenuItem key={v} value={v}>
                        {t("generic.country." + v)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {/*
               * FIELD
               */}
              <Box pt={cls.hSpacing}>
                <Typography gutterBottom variant={cls.iptHeader}>
                  {t("order.provider-details.details.publicDescription.header")}
                </Typography>
                <Typography gutterBottom variant={cls.iptSubHeader}>
                  {t(
                    "order.provider-details.details.publicDescription.subHeader"
                  )}
                </Typography>
              </Box>
              <Box mt={cls.iptSpacing}>
                <TextField
                  error={errors.publicDescription}
                  disabled={disabled}
                  variant="outlined"
                  required
                  fullWidth
                  multiline={true}
                  rows={10}
                  id="publicDescription"
                  label={t(
                    "order.provider-details.details.publicDescription.label"
                  )}
                  name="publicDescription"
                  helperText={
                    errors.publicDescription &&
                    t("generic.validation.string-required")
                  }
                  value={company.publicDescription}
                  onChange={e =>
                    setCompany({
                      ...company,
                      publicDescription: e.target.value
                    })
                  }
                />
              </Box>
              <Box pt={cls.hSpacing}>
                <Typography gutterBottom variant={cls.iptHeader}>
                  {t("order.provider-details.details.consent-header")}
                </Typography>
                <Typography gutterBottom variant={cls.iptSubHeader}>
                  <i>{t("order.provider-details.details.orderLabel")}</i>
                </Typography>
                {container.findersFee === 0 &&
                  container.findersFeeAltText === "" && (
                    <Typography gutterBottom variant={cls.iptSubHeader}>
                      <b>{t("order.provider-details.details.orderNoPrice")}</b>
                    </Typography>
                  )}
                {container.findersFee === 0 &&
                  container.findersFeeAltText !== "" && (
                    <Typography gutterBottom variant={cls.iptSubHeader}>
                      <b>
                        {t(
                          "order.provider-details.details.orderNoPriceAltText"
                        )}
                      </b>
                    </Typography>
                  )}
                {container.findersFee > 0 && (
                  <Typography gutterBottom variant={cls.iptSubHeader}>
                    <b>
                      {t("order.provider-details.details.orderPrice", {
                        containerPrice: {
                          price: containerPrice,
                          currency: container.findersFeeCurrency
                        }
                      })}
                    </b>
                  </Typography>
                )}
              </Box>
            </CardContent>
            <CardActions>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Button
                    disabled={loading}
                    type="button"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    data-testid="input-cancel"
                    className={classes.submit}
                    onClick={handleBack}
                  >
                    <FaArrowLeft />
                    &nbsp;&nbsp;{t("generic.word.back")}
                  </Button>
                </Grid>
                <Grid item xs={8}>
                  <Button
                    disabled={loading}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    data-testid="input-submit"
                    className={classes.submit}
                  >
                    {t("order.provider-details.details.btnOrder")}&nbsp;&nbsp;
                    <FaArrowRight />
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </form>
      </div>
    </>
  );
};

export const ProviderDetailsPage = () => {
  const authenticated = useSelector(isAuthenticated(AuthLevel2));
  const history = useHistory();

  useEffect(() => {
    if (!authenticated) {
      history.push("/order");
    }
  }, [authenticated, history]);

  return <InnerPage />;
};
