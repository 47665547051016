import moment from "moment";
import { useMemo } from "react";
import { jobURL } from "../../../config";
import {
  IntentionEmployeeOnboarding,
  IntentionExpectedProspect,
  IntentionExpectedReferrer
} from "../../../domain/constants";
import { useSourceIntention } from "../../../domain/hooks";

/**
 * useAssumedUserIntention will estimate the current user's intention depending
 * on the state we are currently in.
 *
 * @param {*} jca
 */
export const useAssumedUserIntention = jca => {
  const sourceIntention = useSourceIntention(jca);

  // when EO => referrer
  // when referrer or prospect => prospect
  // simple and easy for now
  const assumedUserIntention = useMemo(() => {
    switch (sourceIntention) {
      case IntentionEmployeeOnboarding:
        return IntentionExpectedReferrer;
      case IntentionExpectedReferrer:
      default:
        return IntentionExpectedProspect;
    }
  }, [sourceIntention]);

  return assumedUserIntention;
};

export const generateShareStuff = (jca, acquiredToken, t) => {
  const job = jca.job;

  if (!job) {
    return { link: "", message: "", subject: "" };
  }

  const url = jobURL(acquiredToken);
  const vartpl = "[[0]]";

  const compile = (str, vars) => {
    vars.forEach((v, k) => {
      str = str.replace(vartpl.replace(0, k), v());
    });
    return str;
  };

  const variables = new Map([
    ["jobtitle", () => job.title],
    ["location", () => job.location],
    ["findersFee", () => job.findersFee],
    ["findersFeeCurrency", () => job.findersFeeCurrency],
    [
      "deadline",
      () => {
        return moment(job.deadline).format(
          t("generic.format.dateFormat.short")
        );
      }
    ],
    ["link", () => url]
  ]);

  const template = {
    subject: t("referal.generate-share-stuff.template.share-dialog.subject"),
    body: t("referal.generate-share-stuff.template.share-dialog.body")
  };

  const isPresent = str => str && ("" + str).trim().length > 0;

  // determine if texts have been overwritten
  // higher prio text on company
  if (job.company) {
    if (isPresent(job.company.jobInvitationSubject)) {
      template.subject = job.company.jobInvitationSubject;
    }
    if (isPresent(job.company.jobInvitationText)) {
      template.body = job.company.jobInvitationText;
    }
  }

  return {
    subject: compile(template.subject, variables),
    message: compile(template.body, variables),
    messageWithoutLink: compile(
      template.body.replace("[[link]]", ""),
      variables
    ),
    link: url
  };
};
