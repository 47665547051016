import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { apiURL } from "../../config";
import { ErrorCard } from "../../generics/components";
import { defaultErrorBehaviour } from "../../generics/errors/actions";
import { fireAuthenticated } from "../actions";
import { AuthLevel2 } from "../const";
import { ErrJWTInvalid } from "../errors";
import { isAuthenticated } from "../selectors";

const MagicLink = props => {
  const { jwt } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const authenictaed = useSelector(isAuthenticated(AuthLevel2));

  const [linkInvalid, setLinkInvalid] = useState(false);
  useEffect(() => {
    (async () => {
      try {
        // refresh token. this will refresh and validate in one
        const res = await axios.put(
          apiURL("/auth"),
          {},
          {
            headers: { Authorization: jwt }
          }
        );

        // tell app that we're authenticated
        dispatch(fireAuthenticated(res.data.token));
      } catch (error) {
        const action = defaultErrorBehaviour(error);

        if (action.message === ErrJWTInvalid) {
          // link is outdated. show corresponding message
          setLinkInvalid(true);
        } else {
          // failed refreshing the token. forget about stored authentication
          dispatch(action);
        }
      }
    })();
  }, [jwt, dispatch]);

  useEffect(() => {
    if (authenictaed === true) {
      // decode JWT and forward to frontendURL
      const decoded = jwt_decode(jwt);
      history.push(decoded.frontendUri);
    }
  }, [authenictaed, jwt, history]);

  return (
    <>
      {!linkInvalid && <CircularProgress />}

      {linkInvalid && (
        <ErrorCard
          title="auth.magic-link.error-card.title"
          text="auth.magic-link.error-card.text"
        ></ErrorCard>
      )}
    </>
  );
};

export default MagicLink;
