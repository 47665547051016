import {
  CardActions,
  CardContent,
  CardHeader,
  makeStyles
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import React from "react";
import { Helmet } from "react-helmet";
import { FaArrowLeft, FaPrint } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { apiURL } from "../../config";
import { useJCAFromToken } from "../../domain/hooks";
import { CardText } from "../../generics/components/card-text";
import { FaIconButton } from "../../generics/components/fa-icon-button";

const useLocalStyles = makeStyles(() => ({
  actionButton: {
    fontWeight: 700,
    minWidth: "50%"
  }
}));

export const ReferralPrintPage = props => {
  const shareToken = props.match.params.id;
  const classes = useLocalStyles();
  const jca = useJCAFromToken(shareToken);
  const history = useHistory();

  if (!jca) {
    return <></>;
  }

  var url = apiURL(`/rt-jv/${shareToken}/print`);

  return (
    <>
      <Helmet>
        <title>Stelle drucken</title>
      </Helmet>

      <Card>
        <CardHeader title="Aushang drucken"></CardHeader>
        <CardContent>
          <CardText>
            Sie können ganz einfach einen Aushang Ihrer Stelle drucken. Diesen
            können Sie beispielsweise in der Kantine aushängen. Klicken Sie dazu
            einfach auf den folgenden Button und nutzen Sie bequem die
            Druckfunktion Ihres Web-Browsers.
          </CardText>
        </CardContent>
        <CardActions>
          <FaIconButton
            onClick={() => history.push(`/${shareToken}`)}
            icon={<FaArrowLeft />}
            text={"Startseite"}
            className={classes.actionButton}
          />

          <FaIconButton
            onClick={() => (window.location.href = url)}
            buttonStyle="primary"
            icon={<FaPrint />}
            text={"Druckansicht"}
            className={classes.actionButton}
          />
        </CardActions>
      </Card>
    </>
  );
};
