import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { CenteredInCard } from "../../../generics/components/centered-in-card";
import { DesktopShareButton } from "./desktop-share-buttons";
import { MobileShareButtons } from "./mobile-share-buttons";
import { isMobile } from "react-device-detect";
import { FaCopy } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { CardText } from "../../../generics/components/card-text";

export const ShareOptionsPanel = props => {
  const { t } = useTranslation();

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <CardText>
          {t(
            "referal.referrer-or-prospect-referrer-share-card.card-content.body"
          )}
        </CardText>
      </Grid>

      <Grid item xs={12}>
        {isMobile && (
          <MobileShareButtons
            handleShareMediumClick={props.handleShareMediumClick}
          ></MobileShareButtons>
        )}
      </Grid>

      <Grid item xs={12}>
        {!isMobile && (
          <DesktopShareButton
            handleShareMediumClick={props.handleShareMediumClick}
          ></DesktopShareButton>
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body2" component="p">
          {t("referal.share-card-inlay.body")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {/* removing link preview. was confusing for carina */}
        {/* 
                <CenteredInCard>
                <TextField label="Link" disabled={true} value={clipboardJobLink} />
                </CenteredInCard>
              */}

        <CenteredInCard>
          <Button
            color="secondary"
            variant="contained"
            onClick={props.handleCopyLinkToClipboard}
          >
            <FaCopy />
            &nbsp;{t("referal.share-card-inlay.button")}
          </Button>
        </CenteredInCard>
      </Grid>
    </Grid>
  );
};
