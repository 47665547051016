import { isMobile } from "react-device-detect";
import { Collapse } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import { scroller, Element } from "react-scroll";
import { FaIconButton } from "../../../generics/components/fa-icon-button";
import { useDispatch } from "react-redux";
import { fireLogInteraction } from "../../../domain/actions";
import {
  ActionInteractionContainerDetails,
  ActionInteractionContainerDetailsLess
} from "../../../domain/constants";
import { JobDetailsCardHeader } from "../job-details-card-header";
import ActionButtons from "../referrer-or-prospect-referrer-start-card/action-buttons";

const sep = "||";

const useSeparatedText = text => {
  const [shortText, setShortText] = useState("");
  const [longText, setLongText] = useState("");

  useEffect(() => {
    const sepPos = text.indexOf(sep);

    if (sepPos < 0) {
      setShortText("");
      setLongText(text);
      return;
    }

    setShortText(text.substr(0, sepPos));
    setLongText(text.substr(sepPos + sep.length));
  }, [text]);

  return [shortText, longText];
};

const ReferrerOrProspectJobDetailsCard = ({
  jca,
  fullHeader = true,
  onRefer,
  onMakeContact,
  onTipp
}) => {
  const { t } = useTranslation();
  const alwaysShowFullDescr = true;
  const [showFullDescr, setShowFullDescr] = useState(alwaysShowFullDescr);
  const dispatch = useDispatch();
  const [shortText, longText] = useSeparatedText(jca.job.description);
  const showActionButtons = useMemo(() => onRefer && onMakeContact, [
    onRefer,
    onMakeContact
  ]);

  const toggleHandleReadMore = sfd => {
    if (sfd === false) {
      if (isMobile) {
        scroller.scrollTo("job-details-start", {
          duration: 500,
          delay: 0,
          smooth: "easeInOutQuart",
          offset: -75
        });
      }

      dispatch(fireLogInteraction(jca, ActionInteractionContainerDetailsLess));
      setTimeout(() => {
        setShowFullDescr(false);
      }, 510);
    } else {
      dispatch(fireLogInteraction(jca, ActionInteractionContainerDetails));
      setShowFullDescr(sfd);
    }
  };

  return (
    <Card>
      <Element name="job-details-start"></Element>
      {fullHeader === true && <JobDetailsCardHeader jca={jca} />}

      <CardContent>
        <ReactMarkdown source={shortText} escapeHtml={false} />

        <Collapse in={showFullDescr} timeout="auto" unmountOnExit>
          <ReactMarkdown source={longText} escapeHtml={false} />

          {/* COMPANY TITLE AND DESCRIPTION */}
          <Typography variant="h6">{jca.company.companyName}</Typography>
          <ReactMarkdown
            source={jca.company.publicDescription}
            escapeHtml={false}
          ></ReactMarkdown>
        </Collapse>

        {/* show recommend & contact buttons */}
        {showActionButtons && (
          <ActionButtons
            jca={jca}
            onMakeContact={onMakeContact}
            onTipp={onTipp}
            onRefer={onRefer}
          />
        )}
      </CardContent>

      {!alwaysShowFullDescr && !showFullDescr && (
        <CardActions>
          <FaIconButton
            icon={<FaAngleDown />}
            buttonStyle="secondary"
            text={t("generic.word.show-more")}
            onClick={() => toggleHandleReadMore(true)}
          />
        </CardActions>
      )}

      {!alwaysShowFullDescr && showFullDescr && (
        <CardActions>
          <FaIconButton
            icon={<FaAngleUp />}
            text={t("generic.word.show-less")}
            onClick={() => toggleHandleReadMore(false)}
          />
        </CardActions>
      )}
    </Card>
  );
};

ReferrerOrProspectJobDetailsCard.propTypes = {
  jca: PropTypes.object,
  scenario: PropTypes.oneOf(["referrer", "prospect", "none"])
};

export default ReferrerOrProspectJobDetailsCard;
