import Button from "@material-ui/core/Button";
import React, { useMemo } from "react";

export const FaIconButton = ({ icon, text, buttonStyle, ...props }) => {
  const color = useMemo(
    () => (buttonStyle === "primary" ? "primary" : "secondary"),
    [buttonStyle]
  );
  const variant = useMemo(
    () =>
      buttonStyle === "primary" || buttonStyle === "secondary"
        ? "contained"
        : "text",
    [buttonStyle]
  );

  return (
    <Button color={color} variant={variant} {...props}>
      {icon}&nbsp;&nbsp;{text}
    </Button>
  );
};
