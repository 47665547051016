export const STATE_WAITING = 0x000;
export const STATE_VIEW = 0x001;
export const STATE_EDIT_EMAIL = 0x002;
export const STATE_EDIT_DETAILS = 0x004;

export const PREF_RETREE_NOTIFICATIONS_ENABLED =
  "PrefRetreeNotificationsEnabled";

export const PREF_GENERIC_YES = "1";
export const PREF_GENERIC_NO = "0";
