import {
  Button,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEnvelope } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import { useDispatch } from "react-redux";
import { apiURL } from "../../config";
import { CenteredInCard } from "../../generics/components/centered-in-card";
import { defaultErrorBehaviour } from "../../generics/errors/actions";
import * as validators from "../../generics/validators";
import { useStyles } from "../../theme/classes";

const TippForm = ({ transitSent, jca }) => {
  // DTO for registration
  const initialDTO = {
    name: "",
    address: ""
  };

  const classes = useStyles();
  const isMounted = useRef(true);
  const [dto, setDTO] = useState(initialDTO);
  const [legalChecked, setLegalChecked] = useState(false);
  const [legalCheckedErr, setLegalCheckedErr] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(
    validators.deriveNoErrorsObj(initialDTO)
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // submit form and process result
  const submit = async () => {
    // everything valid - create identity!
    try {
      setLoading(true);
      await axios.patch(
        apiURL(`/rt-jv/${jca.association.customToken.token}/tipp`),
        dto
      );
      transitSent();
    } catch (error) {
      dispatch(defaultErrorBehaviour(error));
    } finally {
      if (isMounted.current) setLoading(false);
    }
  };

  // handleForm validation etc. calls submit when there are no errors
  const handleForm = async event => {
    event.preventDefault();
    event.stopPropagation();

    setLegalCheckedErr(!legalChecked);

    const errs = {
      name: validators.isEmpty(dto.name),
      address: validators.isEmpty(dto.address)
    };
    setErrors(errs);

    if (legalChecked && validators.hasNoError(errs)) {
      submit();
    }
  };

  return (
    <>
      <form className={classes.form} onSubmit={handleForm} noValidate>
        <Card className={classes.root}>
          <CardHeader
            title={
              <>
                <Typography variant="h5">
                  {t("referal.referrer-or-prospect-tipp.tipp-card.header")}
                </Typography>
              </>
            }
          />
          <CardContent>
            <ReactMarkdown
              source={t(
                "referal.referrer-or-prospect-tipp.tipp-card.subHeader"
              )}
              escapeHtml={false}
            />
            <Grid container spacing={2}>
              <Grid item xs={12}></Grid>
              <Grid item xs={12}>
                <TextField
                  error={errors.name}
                  disabled={loading}
                  variant="outlined"
                  required
                  fullWidth
                  id="name"
                  label={t(
                    "referal.referrer-or-prospect-tipp.tipp-card.input-name"
                  )}
                  name="name"
                  autoComplete="name"
                  inputProps={{
                    "data-testid": "input-name"
                  }}
                  helperText={
                    errors.name &&
                    t(
                      "referal.referrer-or-prospect-tipp.tipp-card.input-name-err"
                    )
                  }
                  value={dto.name}
                  onChange={e => setDTO({ ...dto, name: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={errors.address}
                  disabled={loading}
                  variant="outlined"
                  required
                  fullWidth
                  id="address"
                  label={t(
                    "referal.referrer-or-prospect-tipp.tipp-card.input-address"
                  )}
                  name="address"
                  autoComplete="address"
                  inputProps={{
                    "data-testid": "input-address"
                  }}
                  helperText={
                    errors.address && t("generic.validation.any-address")
                  }
                  value={dto.address}
                  onChange={e => setDTO({ ...dto, address: e.target.value })}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  required
                  error={legalCheckedErr}
                  component="fieldset"
                  sx={{ m: 3 }}
                  variant="standard"
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={e => {
                          setLegalChecked(e.target.checked);
                        }}
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="body2">
                        {t(
                          "referal.referrer-or-prospect-tipp.tipp-card.checkbox-legal"
                        )}
                      </Typography>
                    }
                  />
                  {legalCheckedErr && (
                    <FormHelperText>
                      {t(
                        "referal.referrer-or-prospect-tipp.tipp-card.legal-checked-err"
                      )}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <CenteredInCard>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleForm}
                  >
                    <FaEnvelope /> &nbsp;
                    {t("referal.referrer-or-prospect-tipp.tipp-card.btn-send")}
                  </Button>
                </CenteredInCard>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </form>
    </>
  );
};

export const TippCard = ({ transitSent, jca }) => {
  return <TippForm transitSent={transitSent} jca={jca} />;
};
