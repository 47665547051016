import Axios from "axios";
import { setup } from "axios-cache-adapter";
import localforage from "localforage";
import config from "../config";

// `async` wrapper to configure `localforage` and instantiate `axios` with `axios-cache-adapter`
export const cachingAxios = () => {
  if (
    !config.enableAxiosCache ||
    ("" + window.location.search).indexOf("nocache") >= 0
  ) {
    return Axios;
  }

  // Create `localforage` instance
  const forageStore = localforage.createInstance({
    // List of drivers used
    driver: [
      // localforage.INDEXEDDB,
      localforage.LOCALSTORAGE
    ],
    // Prefix all storage keys to prevent conflicts
    name: "b2j"
  });

  // Create `axios` instance with pre-configured `axios-cache-adapter` using a `localforage` store
  return setup({
    cache: {
      maxAge: config.axiosCacheMaxAge,
      store: forageStore // Pass `localforage` store to `axios-cache-adapter`
    }
  });
};
