import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { isAuthenticated } from "../../selectors";
import { StateConfirmIdentity } from "./state-confirm-identity";
import {
  STATE_CONFIRM_IDENTITY,
  STATE_DETAILS,
  STATE_FINISHED,
  STATE_OTP,
  STATE_OTP_LIMIT_EXCEEDED,
  STATE_PASSWORD,
  STATE_START,
  STATE_UNSET
} from "./constants";
import { StateDetails } from "./state-details";
import { StateStart } from "./state-start";
import { StateFinished } from "./state-finished";
import { StateOTP } from "./state-otp";
import { StateOTPLimitExceeded } from "./state-otp-limit-exceeded";
import { StatePassword } from "./state-password";

const Wizard = ({
  authLevel,
  detailsRequired = true,
  customStartStateHeader = null,
  customDetailsStateHeader = null,
  requireLastName = true,
  yourEmailAddressLabel = "",
  onFinished = () => null,
  onTransitState = () => null,
  cancelable = false,
  forceNoPreventSignup = false,
  children
}) => {
  const [state, setState] = useState(STATE_UNSET);
  const [sharedState, setSharedState] = useState({});
  const authenticated = useSelector(isAuthenticated(authLevel));
  const isMounted = useRef(true);

  const transitState = useCallback(
    newState => {
      console.log("Wizard#transitState old => new ", state, newState);
      setState(newState);
      onTransitState(newState);
    },
    [state, onTransitState]
  );

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // try to quickly determine best matching state
  useEffect(() => {
    if (isMounted.current) {
      if (state === STATE_UNSET) {
        if (authenticated) {
          transitState(STATE_DETAILS);
        } else {
          transitState(STATE_START);
        }
      }
    }
  }, [transitState, state, authenticated]);

  let innerComponent;

  switch (state) {
    case STATE_START:
      innerComponent = (
        <StateStart
          authLevel={authLevel}
          transitState={transitState}
          sharedState={sharedState}
          setSharedState={setSharedState}
          yourEmailAddressLabel={yourEmailAddressLabel}
          forceNoPreventSignup={forceNoPreventSignup}
          customStartStateHeader={customStartStateHeader}
          cancelable={cancelable}
        />
      );
      break;
    case STATE_CONFIRM_IDENTITY:
      innerComponent = (
        <StateConfirmIdentity
          transitState={transitState}
          sharedState={sharedState}
          detailsRequired={detailsRequired}
        />
      );
      break;
    case STATE_DETAILS:
      innerComponent = (
        <StateDetails
          transitState={transitState}
          sharedState={sharedState}
          detailsRequired={detailsRequired}
          requireLastName={requireLastName}
          customDetailsStateHeader={customDetailsStateHeader}
        />
      );
      break;
    case STATE_OTP:
      innerComponent = (
        <StateOTP transitState={transitState} sharedState={sharedState} />
      );
      break;
    case STATE_OTP_LIMIT_EXCEEDED:
      innerComponent = (
        <StateOTPLimitExceeded
          transitState={transitState}
          sharedState={sharedState}
        />
      );
      break;
    case STATE_PASSWORD:
      innerComponent = (
        <StatePassword
          transitState={transitState}
          setSharedState={setSharedState}
          sharedState={sharedState}
        />
      );
      break;
    case STATE_FINISHED:
      onFinished();
      innerComponent = <StateFinished>{children}</StateFinished>;
      break;
    default:
      innerComponent = <></>;
      break;
  }

  return <>{innerComponent}</>;
};

export default Wizard;
