import { Box, Grid, Paper } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import * as loadingSelectors from "../../generics/loading/selectors";
import * as authActions from "../actions";
import { Wizard } from "../components/wizard";
import { WizardTitle } from "../components/wizard/wizard-title";
import { AuthLevel2 } from "../const";
import { isAuthenticated } from "../selectors";

/**
 * Shown when trying to access a PrivateRoute.
 * Will rediect back to original route after successful authentication.
 */
const Authentication = props => {
  const authenticated = useSelector(isAuthenticated(AuthLevel2));
  const { t } = useTranslation();

  const loading = useSelector(
    loadingSelectors.contextLoading(authActions.LOADING_CONTEXT_BACKGROUND)
  );

  const { from } = props.location.state || { from: { pathname: "/" } };

  if (loading) {
    // while identity is loading action for sure is checking
    // if our current token is still valid. if so, just wait.
    return <></>;
  } else if (authenticated === true) {
    // if we are authenticated redirect to original destination
    return <Redirect to={from} />;
  }

  // if neither nore, show authentication form
  return (
    <>
      <Paper>
        <Box p={1}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <img
                alt="Block2Job Logo"
                style={{ width: "100%" }}
                src="https://block2job.com/wp-content/uploads/2020/10/b2j_logo_app.png"
              ></img>
            </Grid>
          </Grid>
        </Box>
        <Box p={1}>
          <Wizard
            authLevel={AuthLevel2}
            customStartStateHeader={
              <WizardTitle
                title={t("auth.start-page.title")}
                text={t("auth.start-page.description")}
              />
            }
            detailsRequired={false}
          />
        </Box>
      </Paper>
    </>
  );
};

export default Authentication;
