import { Snackbar } from "@material-ui/core";
import MUIAlert from "@material-ui/lab/Alert";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { hideError } from "./actions";

const Alert = () => {
  const error = useSelector(state => state.error);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideError());
  };

  if (error.present) {
    // try to get error message by status
    let key = error.i18n + "." + error.status;
    let alertMessage = t(key);

    // get error message by message if not found by status
    if (alertMessage === key && error.message) {
      const specificKey = error.i18n + "." + error.message;
      alertMessage = t(specificKey);
      if (alertMessage === specificKey) {
        alertMessage = t("error." + error.message);
      }
    }

    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={error.show}
        onClose={handleClose}
      >
        <MUIAlert onClose={handleClose} variant="filled" severity="error">
          {alertMessage}
        </MUIAlert>
      </Snackbar>
    );
  } else {
    return <></>;
  }
};

export default Alert;
