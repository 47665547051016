import { CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { ExternalLink } from "react-external-link";
import { useTranslation } from "react-i18next";
import { FaLink } from "react-icons/fa";
import config from "../../config";
import { CardText } from "../../generics/components/card-text";

export const PlatformADCard = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader
        title={
          <>
            <Typography variant="h6">
              {t("referal.platform-ad-card.header")}
            </Typography>
          </>
        }
      />

      <CardContent>
        <CardText>{t("referal.platform-ad-card.body")}</CardText>
        <ExternalLink
          href={config.website}
          style={{
            color: "#00a0b2",
            textDecoration: "none",
            paddingTop: "15px",
            display: "block"
          }}
        >
          <FaLink /> {t("referal.platform-ad-card.button")}
        </ExternalLink>
      </CardContent>
    </Card>
  );
};
