import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { MediumEmail } from "../../../domain/constants";
import { CenteredInCard } from "../../../generics/components/centered-in-card";
import { FaEnvelope } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { FaQrcode } from "react-icons/fa";
import { ShareButton } from "./share-button";
import { MediumQR } from "../../../domain/constants";
export const DesktopShareButton = ({ handleShareMediumClick }) => {
  const { t } = useTranslation();
  return (
    <Grid item xs={12}>
      <Grid container spacing={2} alignItems="center" direction="column">
        <ShareButton
          text={t("referal.desktop-share-button.button")}
          handler={handleShareMediumClick}
          medium={MediumEmail}
        >
          <FaEnvelope />
        </ShareButton>
        <ShareButton
          text={t("referal.desktop-share-button.qr")}
          handler={handleShareMediumClick}
          medium={MediumQR}
        >
          <FaQrcode />
        </ShareButton>

        {/* <ShareButton
          text={t("referal.desktop-share-button.button")}
          handler={handleShareMediumClick(MediumEmail)}
        >
          <FaEnvelope />
        </ShareButton> */}
      </Grid>
      <CenteredInCard>
        <Typography variant="caption">
          {t("referal.desktop-share-button.caption")}
        </Typography>
      </CenteredInCard>
    </Grid>
  );
};
