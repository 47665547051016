import axios from "axios";
import { apiURL } from "../config";
import { defaultErrorBehaviour } from "../generics/errors/actions";
import { flashMessage } from "../generics/flash";

export const DOMAIN_LOADING_CONTEXT = "domain";
export const DOMAIN_JOB_LOADING_CONTEXT = "domainJob";

export const ACTION_DOMAIN_STORE_JCA = "ACTION_DOMAIN_STORE_JCA";
export const ACTION_DOMAIN_STORE_JCAS = "ACTION_DOMAIN_STORE_JCAS";

export const ACTION_DOMAIN_UPDATE_JCA_ARCHIVED =
  "ACTION_DOMAIN_UPDATE_JCA_ARCHIVED";

export const ACTION_UPDATE_JCAS_FILTER = "ACTION_UPDATE_JCAS_FILTER";

export const SORT_TITLE = 0;
export const SORT_DEADLINE = 1;

export const storeJCA = jca => {
  return {
    type: ACTION_DOMAIN_STORE_JCA,
    jca
  };
};

export const storeJCAs = jcas => {
  return {
    type: ACTION_DOMAIN_STORE_JCAS,
    jcas
  };
};

export const updateJCAArchived = jca => ({
  type: ACTION_DOMAIN_UPDATE_JCA_ARCHIVED,
  jobId: jca.job.id
});

export const updateJCAsFilter = filter => {
  return {
    type: ACTION_UPDATE_JCAS_FILTER,
    filter
  };
};

export const jobSharedAPICall = shareToken => {
  return axios.patch(apiURL(`/rt-jv/${shareToken}/shared`));
};

export const fireArchiveJCA = jca => {
  return async dispatch => {
    try {
      await axios.patch(
        apiURL(`/rt-jv/${jca.association.customToken.token}/archived`)
      );
      dispatch(
        flashMessage("domain.actions.submit-job-archived.success", "success")
      );
      dispatch(updateJCAArchived(jca));
    } catch (error) {
      dispatch(defaultErrorBehaviour(error));
    }
  };
};

export const fireLogInteraction = (jca, interaction) => {
  return async dispatch => {
    try {
      let token = "";
      if (!!jca.association.customToken) {
        token = jca.association.customToken.token;
      } else {
        token = jca.association.sourceToken.token;
      }

      await axios.patch(apiURL(`/rt-jv/${token}/i/${interaction}`));
    } catch (error) {
      console.error("il", error);
    }
  };
};
