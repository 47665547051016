export const STATUS_STARTED = "loading_started";
export const STATUS_FINISHED = "loading_finished";
export const ACTION_UPDATE_LOADING_STATUS = "ACTION_UPDATE_LOADING_STATUS";
export const ACTION_SET_DISABLE_BACKDROP_CLICK_HINT =
  "ACTION_SET_DISABLE_BACKDROP_BLICK_HINT";

/**
 * setDisableBackgropClickHint will simply set a flag in state. If true, opened dialogs can disable their
 * backdrop-click-close behavior. This is useful when doint OTP and users are navigating between browser
 * and email client. See https://block2job.atlassian.net/browse/MS-153
 * @param {bool} val
 */
export const setDisableBackgropClickHint = val => {
  return {
    type: ACTION_SET_DISABLE_BACKDROP_CLICK_HINT,
    value: val
  };
};

export const updateLoadingStatus = (status, context) => ({
  type: ACTION_UPDATE_LOADING_STATUS,
  status: status,
  context: context
});
