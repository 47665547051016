import { Grid, InputAdornment, TextField } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaIndustry, FaSearch, FaSortAlphaDown } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  SORT_DEADLINE,
  SORT_TITLE,
  updateJCAsFilter
} from "../../../domain/actions";
import { selectJCAs, selectJCAsFilter } from "../../../domain/selectors";
import { FaIconButton } from "../../../generics/components/fa-icon-button";

const PUIFilter = ({ jcas }) => {
  const dispatch = useDispatch();
  const allJCAs = useSelector(selectJCAs());
  const { t } = useTranslation();
  const filter = useSelector(selectJCAsFilter());

  //
  // stuff related to sorting
  //
  const [sortingAnchorEl, setSortingAnchorEl] = useState(null);
  const [filterByCompanyAnchorEl, setFilterByCompanyAnchorEl] = useState(null);
  const sortOptions = [
    { c: SORT_TITLE, t: t("power-ui.pui-filter.sort-by-title") },
    { c: SORT_DEADLINE, t: t("power-ui.pui-filter.sort-by-deadline") }
  ];
  const currentSortingOption = useMemo(
    () => sortOptions.find(e => e.c === filter.sorting).t,
    [filter, sortOptions]
  );

  const companyFilterOptions = useMemo(() => {
    if (allJCAs.length === 0) {
      return [];
    }

    let res = [];
    if (allJCAs) {
      for (let i = 0; i < allJCAs.length; i++) {
        if (res.findIndex(added => added.id === allJCAs[i].company.id) < 0) {
          res.push(allJCAs[i].company);
        }
      }
    }
    return res.sort((a, b) => a.companyName.localeCompare(b.companyName));
  }, [allJCAs]);

  const companyFilterButtonLabel = useMemo(() => {
    const selectedCompany = companyFilterOptions.find(
      company => company.id === filter.companyId
    );

    if (filter.companyId === -1 || !selectedCompany) {
      return t("power-ui.pui-filter.company-filter-label");
    }

    const maxLength = 10;

    return selectedCompany.companyName.length < maxLength
      ? selectedCompany.companyName
      : selectedCompany.companyName.substr(0, maxLength) + "...";
  }, [filter, companyFilterOptions, t]);

  console.log("companyFilterOptions", companyFilterOptions);

  // handleSortButtonClick will show sorting menu
  const handleSortButtonClick = event => {
    setSortingAnchorEl(event.currentTarget);
  };

  // handleFilterCompanyButtonClick will show company filter menu
  const handleFilterCompanyButtonClick = event => {
    setFilterByCompanyAnchorEl(event.currentTarget);
  };

  // applySorting by updating filter in store
  const applySorting = option => {
    setSortingAnchorEl(null);

    dispatch(
      updateJCAsFilter({
        ...filter,
        sorting: option
      })
    );
  };

  // applyCompanyFilter by updating filter in store
  const applyCompanyFilter = option => {
    setFilterByCompanyAnchorEl(null);

    dispatch(
      updateJCAsFilter({
        ...filter,
        companyId: option
      })
    );
  };

  // applyTextSearch by updating filter in store
  const applyTextSearch = value => {
    dispatch(
      updateJCAsFilter({
        ...filter,
        textSearch: value
      })
    );
  };

  return (
    <>
      <Menu
        anchorEl={filterByCompanyAnchorEl}
        keepMounted
        open={Boolean(filterByCompanyAnchorEl)}
        onClose={() => setFilterByCompanyAnchorEl(null)}
      >
        <MenuItem
          key={"-1"}
          selected={-1 === filter.companyId}
          onClick={() => applyCompanyFilter(-1)}
        >
          Alle
        </MenuItem>

        {companyFilterOptions.map(company => (
          <MenuItem
            key={company.id.toString()}
            selected={company.id === filter.companyId}
            onClick={() => applyCompanyFilter(company.id)}
          >
            {company.companyName}
          </MenuItem>
        ))}
      </Menu>

      <Menu
        anchorEl={sortingAnchorEl}
        keepMounted
        open={Boolean(sortingAnchorEl)}
        onClose={() => setSortingAnchorEl(null)}
      >
        {sortOptions.map(option => (
          <MenuItem
            key={option.t}
            selected={option.c === filter.sorting}
            onClick={event => applySorting(option.c)}
          >
            {option.t}
          </MenuItem>
        ))}
      </Menu>

      <Grid container direction="row" spacing={1}>
        <Grid item xs={12} md={5}>
          <TextField
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FaSearch />
                </InputAdornment>
              )
            }}
            value={filter.textSearch}
            onChange={e => applyTextSearch(e.target.value)}
          />
        </Grid>

        <Grid item xs={8} md={4}>
          <FaIconButton
            onClick={handleFilterCompanyButtonClick}
            icon={<FaIndustry />}
            text={companyFilterButtonLabel}
          />
        </Grid>

        <Grid item xs={4} md={3}>
          <FaIconButton
            onClick={handleSortButtonClick}
            icon={<FaSortAlphaDown />}
            text={currentSortingOption}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PUIFilter;
