import {
  ACTION_SET_OWN_IDENTITY,
  ACTION_SET_AUTH_TOKEN,
  ACTION_RESET_AUTHENTICATION,
  ACTION_FLAG_AUTHENTICATED_IN_SESSION
} from "./actions";

//setup initial states
const initialState = {
  self: null,
  authenticatedInSession: null,
  token: null
};

// reducers
const auth = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_FLAG_AUTHENTICATED_IN_SESSION:
      return {
        ...state,
        authenticatedInSession: true
      };
    case ACTION_SET_OWN_IDENTITY:
      return {
        ...state,
        self: action.identity
      };
    case ACTION_SET_AUTH_TOKEN:
      return {
        ...state,
        token: action.token
      };
    case ACTION_RESET_AUTHENTICATION:
      return initialState;
    default:
      return state;
  }
};

export { auth, initialState };
