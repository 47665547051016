import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import QRCode from "qrcode.react";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaDownload } from "react-icons/fa";
import { CardText } from "../../../generics/components/card-text";
import { CenteredInCard } from "../../../generics/components/centered-in-card";
export const ShareByQRCodePanel = ({ jobShareLink, onCancel }) => {
  const { t } = useTranslation();

  const handleQRDownload = e => {
    var canvas = document.querySelector(".HpQrcode > canvas");
    var a = document.createElement("a");
    a.href = canvas.toDataURL("image/jpg");
    a.download = "Block2JobQRCode.png";
    a.click();
    e.preventDefault();
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <CardText>{t("referal.share-by-qr-code-panel.description")}</CardText>
      </Grid>
      <Grid item xs={12}>
        <CenteredInCard>
          <div style={{ display: "inline" }} className="HpQrcode">
            <QRCode size={256} level={"H"} value={jobShareLink} />
          </div>
        </CenteredInCard>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          color="secondary"
          variant="contained"
          onClick={handleQRDownload}
        >
          <FaDownload />
          &nbsp;{t("referal.share-by-qr-code-panel.qr-download")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          color="primary"
          data-testid="button-resetpwd"
          onClick={onCancel}
        >
          {t("generic.word.back")}
        </Button>
      </Grid>
    </Grid>
  );
};
