import React from "react";
import { Route } from "react-router-dom";
import { HomePage } from "./pages";

const HomeRoute = () => (
  <div>
    <Route path="/" exact component={HomePage} />
  </div>
);

export default HomeRoute;
