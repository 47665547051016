import { Grid } from "@material-ui/core";
import React from "react";
import { FaEnvelope, FaSms, FaWhatsapp } from "react-icons/fa";
import {
  MediumEmail,
  MediumSMS,
  MediumWhatsApp
} from "../../../domain/constants";
import { ShareButton } from "./share-button";
import { MediumQR } from "../../../domain/constants";
import { useTranslation } from "react-i18next";
import { FaQrcode } from "react-icons/fa";
export const MobileShareButtons = ({ handleShareMediumClick }) => {
  const { t } = useTranslation();
  return (
    <Grid item xs={12}>
      <Grid container spacing={2} alignItems="center" direction="column">
        <ShareButton
          text="WhatsApp"
          size="large"
          handler={handleShareMediumClick}
          medium={MediumWhatsApp}
        >
          <FaWhatsapp />
        </ShareButton>

        <ShareButton
          text="E-Mail"
          size="large"
          handler={handleShareMediumClick}
          medium={MediumEmail}
        >
          <FaEnvelope />
        </ShareButton>

        <ShareButton
          text="SMS"
          size="large"
          handler={handleShareMediumClick}
          medium={MediumSMS}
        >
          <FaSms />
        </ShareButton>

        <ShareButton
          text={t("referal.desktop-share-button.qr")}
          handler={handleShareMediumClick}
          medium={MediumQR}
        >
          <FaQrcode />
        </ShareButton>
      </Grid>
    </Grid>
  );
};
