import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CardText } from "../../../generics/components/card-text";
import * as actions from "../../actions";
import { authenticatedInSession, currentIdentity } from "../../selectors";
import { useStyles } from "../styles";
import { STATE_DETAILS } from "./constants";
import { StateLegalLinks } from "./state-legal-links";

/**
 * Will be rendered when opening auth wizard while already being authenticated.
 *
 * @param {*} param0
 */
export const StateConfirmIdentity = ({
  transitState,
  detailsRequired = true
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const ownIdentity = useSelector(currentIdentity());
  const dispatch = useDispatch();
  const flaggedAsAuthInSession = useSelector(authenticatedInSession());

  const handleContinue = () => {
    dispatch(actions.flagAuthenticatedInSession());
    transitState(STATE_DETAILS);
  };

  const handleThatsNotMe = () => {
    dispatch(actions.fireResetAuthentication());
  };

  useEffect(() => {
    if (flaggedAsAuthInSession) {
      transitState(STATE_DETAILS);
    }
  }, [transitState, flaggedAsAuthInSession]);

  /* FIXME: i18n */
  return (
    <div data-testid="state-confirm-identity">
      <CardText>{t("auth.confirm-identity.title")}</CardText>
      <form className={classes.form} onSubmit={() => null} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              disabled={true}
              variant="outlined"
              required
              fullWidth
              id="email"
              label={t("auth.confirm-identity.text-field.label")}
              name="email"
              autoComplete="email"
              inputProps={{
                "data-testid": "input-email"
              }}
              value={ownIdentity.email}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              data-testid="input-submit"
              onClick={handleThatsNotMe}
            >
              {t("auth.confirm-identity.button")}
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              data-testid="input-submit"
              className={classes.altSubmit}
              onClick={handleContinue}
            >
              {t("generic.word.yes-next")}
            </Button>
          </Grid>
        </Grid>
      </form>

      <StateLegalLinks />
    </div>
  );
};
