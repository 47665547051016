import { makeStyles } from "@material-ui/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FaEnvelope, FaShare } from "react-icons/fa";
import { usePositionInReferralChain } from "../../../domain/hooks";
import { CardParagraph } from "../../../generics/components/card-paragraph";
import { CardText } from "../../../generics/components/card-text";
import { FaIconButton } from "../../../generics/components/fa-icon-button";

const useLocalStyles = makeStyles(() => ({
  actionButton: {
    fontWeight: 700,
    minWidth: "50%"
  }
}));

const ActionButtons = ({
  jca,
  onRefer,
  onMakeContact,
  onTipp,
  textDetails = true
}) => {
  const classes = useLocalStyles();

  const { t } = useTranslation();

  const chainPosition = usePositionInReferralChain(jca);
  const notChainLenReached = useMemo(
    () => chainPosition.max <= 0 || chainPosition.pos < chainPosition.max,
    [chainPosition]
  );
  const tippingDisabled = useMemo(() => jca.job.tippingDisabled, [jca]);

  return (
    <>
      {!tippingDisabled && notChainLenReached && (
        <CardParagraph>
          <CardText>
            {t(
              "referal.referrer-or-prospect-referrer-start-card.card-content.tipp-appeal",
              {
                findersFee: jca.job.findersFee,
                findersFeeCurrency: jca.job.findersFeeCurrency
              }
            )}
          </CardText>
          <FaIconButton
            disabled={!jca?.job.customerApproved}
            onClick={onTipp}
            buttonStyle="primary"
            icon={<FaShare />}
            text={t(
              "referal.referrer-or-prospect-referrer-start-card.card-content.button4"
            )}
            className={classes.actionButton}
          />
        </CardParagraph>
      )}

      {notChainLenReached && (
        <CardParagraph>
          <CardText>
            {t(
              "referal.referrer-or-prospect-referrer-start-card.card-content.share-appeal",
              {
                findersFee: jca.job.findersFee,
                findersFeeCurrency: jca.job.findersFeeCurrency
              }
            )}
          </CardText>
          <FaIconButton
            disabled={!jca?.job.customerApproved}
            onClick={onRefer}
            buttonStyle="primary"
            icon={<FaShare />}
            text={t(
              "referal.referrer-or-prospect-referrer-start-card.card-content.button1"
            )}
            className={classes.actionButton}
          />
        </CardParagraph>
      )}

      <CardParagraph>
        <CardText>
          {t(
            "referal.referrer-or-prospect-referrer-start-card.card-content.contact-appeal",
            {
              findersFee: jca.job.findersFee,
              findersFeeCurrency: jca.job.findersFeeCurrency
            }
          )}
        </CardText>
        <FaIconButton
          disabled={!jca?.job.customerApproved}
          onClick={onMakeContact}
          buttonStyle="secondary"
          icon={<FaEnvelope />}
          text={t(
            "referal.referrer-or-prospect-referrer-start-card.card-content.button2"
          )}
          className={classes.actionButton}
        />
      </CardParagraph>
    </>
  );
};

export default ActionButtons;
