import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
//importing reducers
import { auth } from "./auth/reducers";
import { domain } from "./domain/reducer";
import error from "./generics/errors/reducer";
import flashReducer from "./generics/flash";
import loading from "./generics/loading/reducer";
import { uiSettingsReducer } from "./generics/uisettings";
import { i18nReducer } from "./i18n/redux";
import proReducer from "./pro-ui/reducer";

export const ACTION_RESET_STORE = "RESET_STORE";

// app reducer configuration
const appReducer = combineReducers({
  auth,
  domain: domain,
  ui: uiSettingsReducer,
  loading,
  error,
  flash: flashReducer,
  language: i18nReducer,
  pro: proReducer
});

// root reducer that can be reset
// https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store
const rootReducer = (state, action) => {
  if (action.type === ACTION_RESET_STORE) {
    state = undefined;
  }

  return appReducer(state, action);
};

//redux store configuration
const middleware = [thunk, logger];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export default createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware))
);
