import { createSelector } from "reselect";

export const contextLoading = context => {
  return createSelector(
    state => state.loading,
    loading => loading.contexts.includes(context)
  );
};

export const isAnyContextLoading = contexts => {
  return createSelector(
    state => state.loading,
    loading =>
      loading.contexts.findIndex(f => contexts.findIndex(c => c === f)) >= 0
  );
};

export const selectDisableBackgropClick = () => {
  return createSelector(
    state => state.loading.disableBackdropClickHint,
    disableBackdropClickHint => disableBackdropClickHint
  );
};
