import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useJCAFromToken } from "../../domain/hooks";
import { ReferalCardGrid } from "../components/referal-card-grid";
import { ReferalCardGridItem } from "../components/referal-card-item";
import ReferrerOrProspectContactFeedbackCard from "../components/referrer-or-prospect-contact-feedback-card/referrer-or-prospect-contact-feedback-card";
import { ReferrerOrProspectJobDetailsCard } from "../components/referrer-or-prospect-job-details-card";

export const ApplicationFeedbackPage = () => {
  const { id, param } = useParams();
  const jca = useJCAFromToken(id);
  const { t } = useTranslation();

  if (!jca) {
    return <></>;
  }
  return (
    <>
      <Helmet>
        <title>
          {t("pages.expected-referrer-or-prospect-scenario.title", {
            jca: jca
          })}
        </title>
      </Helmet>

      <ReferalCardGrid>
        <ReferalCardGridItem>
          <ReferrerOrProspectContactFeedbackCard jca={jca} param={param} />
        </ReferalCardGridItem>

        <ReferalCardGridItem>
          <ReferrerOrProspectJobDetailsCard jca={jca} />
        </ReferalCardGridItem>
      </ReferalCardGrid>
    </>
  );
};
