import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { Alert } from "@material-ui/lab";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaArrowRight } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import { useHistory } from "react-router";
import { CardText } from "../generics/components/card-text";
import * as validators from "../generics/validators";
import { useStyles } from "../theme/classes";
import { AuthWrapper } from "./auth-wrapper";
import { initialContainerDTO, OrderContext } from "./state";

const AuthHeader = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h5">{t("order.auth-wrapper.title")}</Typography>
      <CardText>{t("order.auth-wrapper.subTitle")}</CardText>
    </>
  );
};

const defaultMinValue = 500;

const currencyMinValues = {
  BTC: 0.01,
  ETH: 0.2,
  ADA: 2
};

const InnerPage = () => {
  const forFreeEdition = true;
  const classes = useStyles();
  const isMounted = useRef(true);
  const { container, setContainer, scrollToTop } = useContext(OrderContext);
  const [loading] = useState(false);
  const history = useHistory();
  const [errors, setErrors] = useState(
    validators.deriveNoErrorsObj(initialContainerDTO)
  );
  const { t } = useTranslation();
  const currencyMinValue = useMemo(() => {
    if (currencyMinValues[container.findersFeeCurrency]) {
      return currencyMinValues[container.findersFeeCurrency];
    }
    return defaultMinValue;
  }, [container]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // submit form and process result
  const transitNext = async () => {
    scrollToTop();
    history.push("/order/provider");
  };

  // handleForm validation etc. calls submit when there are no errors
  const handleForm = async event => {
    event.preventDefault();
    event.stopPropagation();

    const errs = {
      title: validators.isEmpty(container.title),
      subTitle: validators.isEmpty(container.subTitle),
      type: validators.isEmpty(container.type),
      location: validators.isEmpty(container.location),
      description: validators.isEmpty(container.description),
      findersFee:
        !forFreeEdition &&
        (validators.isNaFloat(container.findersFee) ||
          (container.findersFee > 0 &&
            container.findersFee < currencyMinValue)),
      findersFeeAltText:
        forFreeEdition && validators.isEmpty(container.findersFeeAltText),
      findersFeeCurrency: validators.isEmpty(container.findersFeeCurrency)
    };
    console.error(errs);
    setErrors(errs);

    setContainer({
      ...container,
      findersFee: parseFloat(container.findersFee)
    });

    if (validators.hasNoError(errs)) {
      transitNext();
    }
  };

  const cls = {
    iptHeader: "h6",
    iptSubHeader: "body2",
    hSpacing: 3,
    iptSpacing: 1
  };

  return (
    <>
      <div data-testid="state-view">
        <form className={classes.form} onSubmit={handleForm} noValidate>
          <Card className={classes.detailsCard}>
            <CardContent>
              <Typography gutterBottom variant="h5">
                {t("order.container-details.details.header")}
              </Typography>
              <Typography variant="body2">
                {t("order.container-details.details.subHeader")}
              </Typography>
              {/*
               * TITLE
               */}
              <Box mt={cls.iptSpacing}>
                <Typography gutterBottom variant={cls.iptHeader}>
                  {t("order.container-details.details.title.header")}
                </Typography>
                <Typography gutterBottom variant={cls.iptSubHeader}>
                  {t("order.container-details.details.title.subHeader")}
                </Typography>
              </Box>
              <Box mt={cls.iptSpacing}>
                <TextField
                  error={errors.title}
                  disabled={loading}
                  variant="outlined"
                  required
                  fullWidth
                  id="title"
                  label={t("order.container-details.details.title.label")}
                  name="title"
                  helperText={
                    errors.title && t("generic.validation.string-required")
                  }
                  value={container.title}
                  onChange={e =>
                    setContainer({ ...container, title: e.target.value })
                  }
                />
              </Box>
              {/*
               * SUB TITLE
               */}
              <Box mt={cls.hSpacing}>
                <Typography gutterBottom variant={cls.iptHeader}>
                  {t("order.container-details.details.subTitle.header")}
                </Typography>
                <Typography gutterBottom variant={cls.iptSubHeader}>
                  {t("order.container-details.details.subTitle.subHeader")}
                </Typography>
              </Box>
              <Box mt={cls.iptSpacing}>
                <TextField
                  error={errors.subTitle}
                  disabled={loading}
                  variant="outlined"
                  required
                  fullWidth
                  id="subTitle"
                  label={t("order.container-details.details.subTitle.label")}
                  name="subTitle"
                  helperText={
                    errors.subTitle && t("generic.validation.string-required")
                  }
                  value={container.subTitle}
                  onChange={e =>
                    setContainer({ ...container, subTitle: e.target.value })
                  }
                />
              </Box>
              {/*
               * FIELD
               */}
              <Box mt={cls.hSpacing}>
                <Typography gutterBottom variant={cls.iptHeader}>
                  {t("order.container-details.details.type.header")}
                </Typography>
                <Typography gutterBottom variant={cls.iptSubHeader}>
                  {t("order.container-details.details.type.subHeader")}
                </Typography>
              </Box>
              <Box mt={cls.iptSpacing}>
                <TextField
                  error={errors.type}
                  disabled={loading}
                  variant="outlined"
                  required
                  fullWidth
                  id="type"
                  label={t("order.container-details.details.type.label")}
                  name="type"
                  helperText={
                    errors.type && t("generic.validation.string-required")
                  }
                  value={container.type}
                  onChange={e =>
                    setContainer({ ...container, type: e.target.value })
                  }
                />
              </Box>
              {/*
               * FIELD
               */}
              <Box mt={cls.hSpacing}>
                <Typography gutterBottom variant={cls.iptHeader}>
                  {t("order.container-details.details.location.header")}
                </Typography>
                <Typography gutterBottom variant={cls.iptSubHeader}>
                  {t("order.container-details.details.location.subHeader")}
                </Typography>
              </Box>
              <Box mt={cls.iptSpacing}>
                <TextField
                  error={errors.location}
                  disabled={loading}
                  variant="outlined"
                  required
                  fullWidth
                  id="location"
                  label={t("order.container-details.details.location.label")}
                  name="location"
                  helperText={
                    errors.location && t("generic.validation.string-required")
                  }
                  value={container.location}
                  onChange={e =>
                    setContainer({ ...container, location: e.target.value })
                  }
                />
              </Box>
              {/*
               * FIELD
               */}
              <Box mt={cls.hSpacing}>
                <Typography gutterBottom variant={cls.iptHeader}>
                  {t("order.container-details.details.description.header")}
                </Typography>
                <Typography variant={cls.iptSubHeader}>
                  {t("order.container-details.details.description.subHeader")}
                </Typography>
              </Box>
              <Box mt={cls.iptSpacing}>
                <TextField
                  error={errors.description}
                  disabled={loading}
                  variant="outlined"
                  required
                  fullWidth
                  id="description"
                  label={t("order.container-details.details.description.label")}
                  name="description"
                  multiline={true}
                  rows={10}
                  helperText={
                    errors.description &&
                    t("generic.validation.string-required")
                  }
                  value={container.description}
                  onChange={e =>
                    setContainer({ ...container, description: e.target.value })
                  }
                />
              </Box>
              {forFreeEdition && (
                <>
                  <Box pt={cls.hSpacing}>
                    <Typography gutterBottom variant={cls.iptHeader}>
                      {t(
                        "order.container-details.details.findersFee.forFreeEdition.header"
                      )}
                    </Typography>
                    <Typography gutterBottom variant={cls.iptSubHeader}>
                      {t(
                        "order.container-details.details.findersFee.forFreeEdition.subHeader"
                      )}
                    </Typography>
                    <Typography gutterBottom variant={cls.iptSubHeader}>
                      <ReactMarkdown>
                        {t(
                          "order.container-details.details.findersFee.forFreeEdition.examples"
                        )}
                      </ReactMarkdown>
                    </Typography>
                  </Box>
                  <Box mt={cls.iptSpacing}>
                    <TextField
                      error={errors.findersFeeAltText}
                      disabled={loading}
                      variant="outlined"
                      required
                      fullWidth
                      id="findersFeeAltText"
                      name="findersFeeAltText"
                      multiline={true}
                      rows={5}
                      helperText={
                        errors.findersFeeAltText &&
                        t("generic.validation.string-required")
                      }
                      value={container.findersFeeAltText}
                      onChange={e =>
                        setContainer({
                          ...container,
                          findersFeeAltText: e.target.value
                        })
                      }
                    />
                  </Box>
                </>
              )}
              {!forFreeEdition && (
                <>
                  {/*
                   * FIELD
                   */}
                  <Box pt={cls.hSpacing}>
                    <Typography gutterBottom variant={cls.iptHeader}>
                      {t("order.container-details.details.findersFee.header")}
                    </Typography>
                    <Typography gutterBottom variant={cls.iptSubHeader}>
                      {t(
                        "order.container-details.details.findersFee.subHeader"
                      )}
                    </Typography>
                    <Alert severity="info">
                      {t("order.container-details.details.findersFee.info0FF")}
                    </Alert>
                  </Box>
                  <Box mt={cls.iptSpacing}>
                    <TextField
                      error={errors.findersFee}
                      disabled={loading}
                      variant="outlined"
                      required
                      type="number"
                      fullWidth
                      id="findersFee"
                      label={t(
                        "order.container-details.details.findersFee.label"
                      )}
                      name="findersFee"
                      helperText={
                        errors.findersFee &&
                        t("order.validation.findersFeeTooLow", {
                          value: {
                            price: currencyMinValue,
                            currency: container.findersFeeCurrency
                          }
                        })
                      }
                      value={container.findersFee}
                      onChange={e =>
                        setContainer({
                          ...container,
                          findersFee: e.target.value
                        })
                      }
                    />
                  </Box>

                  {/*
                   * FIELD
                   */}
                  <Box pt={cls.hSpacing}>
                    <Typography gutterBottom variant={cls.iptHeader}>
                      {t(
                        "order.container-details.details.findersFeeCurrency.header"
                      )}
                    </Typography>
                    <Typography gutterBottom variant={cls.iptSubHeader}>
                      {t(
                        "order.container-details.details.findersFeeCurrency.subHeader"
                      )}
                    </Typography>
                  </Box>
                  <Box mt={cls.iptSpacing}>
                    <Select
                      fullWidth
                      disabled={loading}
                      variant="outlined"
                      id="findersFeeCurrency"
                      label={t(
                        "order.container-details.details.findersFeeCurrency.label"
                      )}
                      value={container.findersFeeCurrency}
                      onChange={e =>
                        setContainer({
                          ...container,
                          findersFeeCurrency: e.target.value
                        })
                      }
                    >
                      <MenuItem value={"EUR"}>Euro (€)</MenuItem>
                      <MenuItem value={"CHF"}>Franken (CHF)</MenuItem>
                      <MenuItem value={"USD"}>U.S. Dollar(USD)</MenuItem>
                      <MenuItem value={"ETH"}>Ethereum (ETH)</MenuItem>
                      <MenuItem value={"BTC"}>Bitcoin (BTC)</MenuItem>
                      <MenuItem value={"ADA"}>Cardano (ADA)</MenuItem>
                    </Select>
                  </Box>
                </>
              )}{" "}
              {/* forFreeEdition && */}
            </CardContent>
            <CardActions>
              <Grid container spacing={2}>
                <Grid item xs={4}></Grid>
                <Grid item xs={8}>
                  <Button
                    disabled={loading}
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    data-testid="input-submit"
                    className={classes.submit}
                  >
                    {t("generic.word.next")}&nbsp;&nbsp;
                    <FaArrowRight />
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </form>
      </div>
    </>
  );
};

export const ContainerDetailsPage = () => {
  return (
    <AuthWrapper wrap={InnerPage} startHeader={<AuthHeader />}></AuthWrapper>
  );
};
