import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { ShareCardInlay } from "../../../referal/components/share-card-inlay";

const PUIShareDialog = ({ open, onClose, jca }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      scroll={"body"}
      open={open}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
    >
      <Box mt={2} mb={2} m={3}>
        <Typography component="p" align="center" variant="caption">
          {t("power-ui.pui-share-dialog.dialog-title-caption", { jca: jca })}
        </Typography>
        <Typography component="p" align="center" variant="h5">
          {t("power-ui.pui-share-dialog.dialog-title", { jca: jca })}
        </Typography>
      </Box>

      <DialogContent>
        <ShareCardInlay jca={jca} />
      </DialogContent>
      <DialogActions>
        <Button fullWidth onClick={onClose} color="secondary">
          {t("power-ui.pui-share-dialog.button-close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PUIShareDialog;
