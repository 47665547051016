import { Grid } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useAllAvailableJCAs } from "../../domain/hooks";
import { selectFilteredJCAs } from "../../domain/selectors";
import { PAppBar } from "../../power-pro-shared/p-app-bar";
import { useStyles } from "../../theme/classes";
import { PUIFilter } from "../components/pui-filter";
import { PUIJobList } from "../components/pui-job-list";

const PUIWatchList = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  // download all available JCAs
  useAllAvailableJCAs();

  // select available JCAs
  const jcas = useSelector(selectFilteredJCAs());

  return (
    <>
      <Helmet>
        <title>{t("power-ui.page.title")}</title>
      </Helmet>
      <PAppBar />

      <Grid
        className={classes.belowAppBar}
        container
        spacing={2}
        direction="column"
      >
        <Grid item>
          <PUIFilter jcas={jcas} />
        </Grid>
        <Grid item>
          <PUIJobList jcas={jcas} />
        </Grid>
      </Grid>
    </>
  );
};

export default PUIWatchList;
