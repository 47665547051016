import { Box, Typography } from "@material-ui/core";
import { default as React } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Element } from "react-scroll";
import { useJCAFromToken } from "../../domain/hooks";
import { CardText } from "../../generics/components/card-text";
import { flashMessage } from "../../generics/flash";
import { ReferalCardGrid } from "../components/referal-card-grid";
import { ReferalCardGridItem } from "../components/referal-card-item";
import { ReferrerOrProspectJobDetailsCard } from "../components/referrer-or-prospect-job-details-card";
import { TippCard } from "../components/tipp-card";
import { AuthWrapper } from "./auth-wrapper";

const AuthHeader = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h6">
        {t("referal.referrer-or-prospect-tipp.auth.caption")}
      </Typography>

      <Box pt={2}>
        <CardText>{t("referal.referrer-or-prospect-tipp.auth.text")}</CardText>
      </Box>
    </>
  );
};

const ProtectedTippPage = props => {
  const { t } = useTranslation();
  const shareToken = props.match.params.id;
  const jca = useJCAFromToken(shareToken);
  const history = useHistory();
  const dispatch = useDispatch();

  const transitTipped = () => {
    history.push(`/${shareToken}`);
    dispatch(
      flashMessage("referal.referrer-or-prospect-tipp.tip-sent", "success")
    );
  };

  if (!jca) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <title>
          {t("referal.referrer-or-prospect-tipp.window-title", {
            jca: jca
          })}
        </title>
      </Helmet>

      <Element name="first-grid-item"></Element>

      <ReferalCardGrid>
        <ReferalCardGridItem>
          <TippCard transitSent={transitTipped} jca={jca} />
        </ReferalCardGridItem>

        <ReferalCardGridItem>
          <ReferrerOrProspectJobDetailsCard jca={jca} />
        </ReferalCardGridItem>
      </ReferalCardGrid>
    </>
  );
};

export const TippPage = props => {
  return (
    <AuthWrapper
      wrap={ProtectedTippPage}
      startHeader={<AuthHeader />}
      {...props}
    ></AuthWrapper>
  );
};
