// eslint-disable-next-line no-unused-vars
export const RTMEM_STATUS_CHAIN_MEMBER = 0x000;

// eslint-disable-next-line no-unused-vars
export const RTMEM_STATUS_APPLIED = 0x010;

// IntentionExpectedReferrer ANS
export const IntentionExpectedReferrer = 0x0000;

// IntentionEmployeeOnboarding ANS
export const IntentionEmployeeOnboarding = 0x0010;

// IntentionExpectedProspect ANS
export const IntentionExpectedProspect = 0x0020;

// IntentionUnintended token that is used for identity to access own job
// this token CAN be shared but should preferably not. happens if user copies URL for instance
export const IntentionUnintended = 0x0030;

// MediumUnknown i.e. default value if not specified
export const MediumUnknown = 0x000;

// MediumEmail ANS
export const MediumEmail = 0x010;

// MediumEmail ANS
export const MediumSMS = 0x020;

// MediumEmail ANS
export const MediumWhatsApp = 0x030;

// MediumEmail ANS
export const MediumTelegram = 0x040;

// MediumQR ANS
export const MediumQR = 0x050;

// ActionInteractionRefer ANS
export const ActionInteractionRefer = 0x0010;
// ActionInteractionApply ANS
export const ActionInteractionApply = 0x0011;
// ActionInteractionReminder ANS
export const ActionInteractionReminder = 0x0012;
// ActionInteractionContainerDetails ANS
export const ActionInteractionContainerDetails = 0x0013;
// ActionInteractionContainerDetailsLess
export const ActionInteractionContainerDetailsLess = 0x0014;
// ActionInteractionPrivacyDeclarationMore ANS
export const ActionInteractionPrivacyDeclarationMore = 0x0015;
// ActionInteractionPrivacyDeclarationFull ANS
export const ActionInteractionPrivacyDeclarationFull = 0x0016;
