export const isEmail = str =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(str);
export const isEmpty = str => !str || "" === str.trim();
export const isNaN = str => !/^[0-9]+$/i.test(str);
export const isNaFloat = str => {
  try {
    var floatValue = +str;
    if (floatValue || floatValue === 0) {
      return false;
    }
  } catch (e) {}
  return true;
};

export const isNotEmail = str => !isEmail(str);
export const isNotEmpty = str => !isEmpty(str);

export const isURL = str => {
  try {
    new URL(str);
  } catch (e) {
    return false;
  }

  return true;
};

export const hasError = obj => {
  let values = Object.keys(obj).map(function(e) {
    return obj[e];
  });
  for (const v of values) {
    if (true === v) {
      return true;
    }
  }

  return false;
};

export const hasNoError = obj => !hasError(obj);

export const deriveNoErrorsObj = dto => {
  let res = {};
  const keys = Object.keys(dto).map(function(e) {
    return dto[e];
  });
  for (const k of keys) {
    res[k] = false;
  }

  return res;
};
