import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography
} from "@material-ui/core";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fireReqOwnIdentity } from "../auth/actions";
import { identityPref } from "../auth/selectors";
import { apiURL } from "../config";
import { CardText } from "../generics/components/card-text";
import { defaultErrorBehaviour } from "../generics/errors/actions";
import { flashMessage, SEVERITY_INFO } from "../generics/flash";
import * as validators from "../generics/validators";
import { useStyles } from "../theme/classes";
import {
  PREF_RETREE_NOTIFICATIONS_ENABLED,
  PREF_GENERIC_YES,
  PREF_GENERIC_NO,
  STATE_EDIT_DETAILS,
  STATE_EDIT_EMAIL,
  STATE_VIEW
} from "./constants";

export const EditEmail = ({ self, transit }) => {
  // DTO for registration
  const initialDTO = {
    email: self.email
  };

  const classes = useStyles();
  const isMounted = useRef(true);
  const [dto, setDTO] = useState(initialDTO);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(
    validators.deriveNoErrorsObj(initialDTO)
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // submit form and process result
  const submit = async () => {
    // everything valid - create identity!
    try {
      setLoading(true);
      await axios.patch(apiURL("/identity/-/email"), dto);

      // show success message
      dispatch(
        flashMessage("identity.edit-email.flash.email-conf-sent", SEVERITY_INFO)
      );

      // continue to finished state
      transit(STATE_VIEW);
    } catch (error) {
      dispatch(defaultErrorBehaviour(error, "generic.error.auth"));
    } finally {
      if (isMounted.current) setLoading(false);
    }
  };

  // handleForm validation etc. calls submit when there are no errors
  const handleForm = async event => {
    event.preventDefault();
    event.stopPropagation();

    const errs = {
      email: validators.isNotEmail(dto.email)
    };
    setErrors(errs);

    if (validators.hasNoError(errs)) {
      submit();
    }
  };

  return (
    <>
      <div data-testid="state-view">
        <form className={classes.form} onSubmit={handleForm} noValidate>
          <Card className={classes.detailsCard}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {t("identity.edit-email.header")}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    error={errors.email}
                    disabled={loading}
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label={t("generic.word.email")}
                    name="email"
                    autoComplete="email"
                    inputProps={{
                      "data-testid": "input-email"
                    }}
                    helperText={errors.email && t("generic.validation.email")}
                    value={dto.email}
                    onChange={e => setDTO({ ...dto, email: e.target.value })}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button
                disabled={loading}
                type="submit"
                size="small"
                fullWidth
                color="primary"
                data-testid="btn-submit"
                className={classes.submit}
              >
                {t("identity.edit-email.card-actions.submit-button")}
              </Button>

              <Button
                disabled={loading}
                size="small"
                fullWidth
                color="primary"
                data-testid="btn-cancel"
                className={classes.submit}
                onClick={() => transit(STATE_VIEW)}
              >
                {t("generic.word.cancel")}
              </Button>
            </CardActions>
          </Card>
        </form>
      </div>
    </>
  );
};

export const EditPersonalDetails = ({ self, transit }) => {
  // DTO for registration
  const initialDTO = {
    firstName: self.firstName,
    lastName: self.lastName
  };

  const classes = useStyles();
  const isMounted = useRef(true);
  const [dto, setDTO] = useState(initialDTO);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(
    validators.deriveNoErrorsObj(initialDTO)
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // submit form and process result
  const submit = async () => {
    // everything valid - create identity!
    try {
      setLoading(true);
      await axios.patch(apiURL("/identity/-/details"), dto);

      dispatch(fireReqOwnIdentity());

      // continue to finished state
      transit(STATE_VIEW);
    } catch (error) {
      dispatch(defaultErrorBehaviour(error, "generic.error.auth"));
    } finally {
      if (isMounted.current) setLoading(false);
    }
  };

  // handleForm validation etc. calls submit when there are no errors
  const handleForm = async event => {
    event.preventDefault();
    event.stopPropagation();

    const errs = {
      firstName: validators.isEmpty(dto.firstName),
      lastName: validators.isEmpty(dto.lastName)
    };
    setErrors(errs);

    if (validators.hasNoError(errs)) {
      submit();
    }
  };

  return (
    <>
      <div data-testid="state-view">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <form className={classes.form} onSubmit={handleForm} noValidate>
              <Card className={classes.root}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    {t("identity.edit-personal-details.title")}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        error={errors.firstName}
                        disabled={loading}
                        variant="outlined"
                        required
                        fullWidth
                        id="firstName"
                        label={t("generic.word.first-name")}
                        name="firstName"
                        autoComplete="firstName"
                        inputProps={{
                          "data-testid": "input-firstName"
                        }}
                        helperText={
                          errors.firstName && t("generic.validation.first-name")
                        }
                        value={dto.firstName}
                        onChange={e =>
                          setDTO({ ...dto, firstName: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        error={errors.lastName}
                        disabled={loading}
                        variant="outlined"
                        required
                        fullWidth
                        id="lastName"
                        label={t("generic.word.last-name")}
                        name="lastName"
                        autoComplete="lastName"
                        inputProps={{
                          "data-testid": "input-lastName"
                        }}
                        helperText={
                          errors.lastName && t("generic.validation.last-name")
                        }
                        value={dto.lastName}
                        onChange={e =>
                          setDTO({ ...dto, lastName: e.target.value })
                        }
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions>
                  <Button
                    disabled={loading}
                    type="submit"
                    size="small"
                    fullWidth
                    color="primary"
                    data-testid="btn-submit"
                    className={classes.submit}
                  >
                    {t("generic.word.save")}
                  </Button>

                  <Button
                    disabled={loading}
                    size="small"
                    fullWidth
                    color="primary"
                    data-testid="btn-cancel"
                    className={classes.submit}
                    onClick={() => transit(STATE_VIEW)}
                  >
                    {t("generic.word.cancel")}
                  </Button>
                </CardActions>
              </Card>
            </form>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export const EditNotifications = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const isMounted = useRef(true);
  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const prefNotificationsEnabled = useSelector(
    identityPref(PREF_RETREE_NOTIFICATIONS_ENABLED, PREF_GENERIC_YES)
  );

  const recieveNotifications = useMemo(
    () => prefNotificationsEnabled === PREF_GENERIC_YES,
    [prefNotificationsEnabled]
  );

  // submit form and process result
  const submit = async () => {
    // everything valid - create identity!
    try {
      setLoading(true);

      const newVal = !recieveNotifications;

      await axios.patch(apiURL("/identity/-/pref"), {
        key: PREF_RETREE_NOTIFICATIONS_ENABLED,
        value: newVal ? PREF_GENERIC_YES : PREF_GENERIC_NO
      });

      dispatch(fireReqOwnIdentity());
    } catch (error) {
      dispatch(defaultErrorBehaviour(error));
    } finally {
      if (isMounted.current) setLoading(false);
    }
  };

  return (
    <>
      <div data-testid="state-view">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card className={classes.root}>
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {t("identity.edit-notifications.header")}
                </Typography>
                <CardText>
                  {t("identity.edit-notifications.description")}
                </CardText>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled={loading}
                          checked={recieveNotifications}
                          onChange={submit}
                          name="notificationsEnabled"
                          color="primary"
                        />
                      }
                      label={t("identity.edit-notifications.checkbox")}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export const DetailsView = ({ self, transit }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div data-testid="state-view">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card className={classes.root}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {t("identity.details-view.header")}
              </Typography>
              <Typography variant="body1" color="textSecondary" component="div">
                <table>
                  <tbody>
                    <tr>
                      <td>{t("generic.word.first-name")}</td>
                      <td data-testid="label-firstName">{self.firstName}</td>
                    </tr>
                    <tr>
                      <td>{t("generic.word.last-name")}</td>
                      <td data-testid="label-lastName">{self.lastName}</td>
                    </tr>
                  </tbody>
                </table>
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                color="primary"
                data-testid="btn-editDetails"
                onClick={() => transit(STATE_EDIT_DETAILS)}
              >
                {t("identity.details-view.card-actions.button1")}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.root}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {t("generic.word.email")}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                data-testid="label-email"
                component="p"
              >
                {self.email}
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                size="small"
                color="primary"
                data-testid="btn-editEmail"
                onClick={() => transit(STATE_EDIT_EMAIL)}
              >
                {t("identity.details-view.card-actions.button2")}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <EditNotifications />
        </Grid>
      </Grid>
    </div>
  );
};
