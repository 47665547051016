// ErrJWTInvalid returned if JWT for instance outdated
export const ErrJWTInvalid = "ErrJWTInvalid";

// ErrMissingJWT returned when we're not authenticated
export const ErrMissingJWT = "ErrMissingJWT";

// ErrIdentityUnknown return if related identity not found
export const ErrIdentityUnknown = "ErrIdentityUnknown";

// ErrOTPSend returned on registration if identity found but no password set
export const ErrOTPSend = "ErrOTPSent";

// ErrOTPNA send if OTP not available ob reset due to too many failed trials
export const ErrOTPNA = "ErrOTPNA";

// ErrLimitExceeded returned on too many OTP generations
export const ErrLimitExceeded = "ErrLimitExceeded";

// ErrPasswordAuthRequired returned on registration if identity found and password set
export const ErrPasswordAuthRequired = "ErrPasswordAuthRequired";

// ErrAuthenticationFailed when OTP or password auth not successful
export const ErrAuthenticationFailed = "ErrAuthenticationFailed";

// ErrInsufficientPermissions returned when accessing admin API without perms
export const ErrInsufficientPermissions = "ErrInsufficientPermissions";
