import { Menu, MenuItem } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import { AccountCircle } from "@material-ui/icons";
import StarIcon from "@material-ui/icons/Star";
import StorefrontIcon from "@material-ui/icons/Storefront";
import { useConfirm } from "material-ui-confirm";
import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { FaPersonBooth, FaSignOutAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Element, scroller } from "react-scroll";
import { fireResetAuthentication } from "../../auth/actions";
import { RoleGlobalPro } from "../../auth/const";
import { currentIdentity, hasRole } from "../../auth/selectors";
import config from "../../config";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  },
  iconButtonLabel: {
    display: "flex",
    flexDirection: "row",
    color: "white",
    fontSize: "14px",
    fontWeight: "bold"
  }
}));

const IconButtonWithLabel = ({ icon, onClick, label }) => {
  const classes = useStyles();

  return (
    <IconButton onClick={onClick} classes={{ label: classes.iconButtonLabel }}>
      {icon}
      <div style={{ marginLeft: "8px" }}>{label}</div>
    </IconButton>
  );
};

const PAppBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const identity = useSelector(currentIdentity());
  const { t } = useTranslation();
  const confirm = useConfirm();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const history = useHistory();
  const identityHasProRole = useSelector(hasRole(RoleGlobalPro));

  const handleShowMenu = event => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleSignOut = async () => {
    setMenuAnchorEl(null);
    confirm({
      description: t("power-pro-shared.p-app-bar.button-sign-off", {
        identity: identity
      })
    }).then(() => dispatch(fireResetAuthentication()));
  };

  const handleIdentity = () => {
    setMenuAnchorEl(null);
    history.push("/identity");
  };

  const scrollToTop = () => {
    scroller.scrollTo("pro-app-bar", {
      duration: 200,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -75
    });
  };

  const handleMarketplace = () => {
    scrollToTop();
    history.push("/pro/market");
  };

  const navigateProWatchList = () => {
    scrollToTop();
    history.push(config.proWatchListRoute);
  };

  const navigatePUIWatchList = () => {
    scrollToTop();
    history.push(config.puiWatchListRoute);
  };

  return (
    <div className={classes.root}>
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
      >
        <MenuItem onClick={handleIdentity}>
          <FaPersonBooth />
          &nbsp;&nbsp;{t("power-pro-shared.p-app-bar.menu.settings")}
        </MenuItem>

        <MenuItem onClick={handleSignOut}>
          <FaSignOutAlt />
          &nbsp;&nbsp;{t("power-pro-shared.p-app-bar.menu.sign-out")}
        </MenuItem>
      </Menu>

      <Element name="pro-app-bar"></Element>

      <AppBar position="fixed">
        <Toolbar>
          <img
            alt="Block2Job Logo"
            style={{ height: "25px" }}
            src="https://block2job.com/wp-content/uploads/2020/10/b2j_logo_app_trans.png"
          ></img>

          <span className={classes.title}></span>
          {/*
          <IconButton
            color="inherit"
            onClick={() => history.push("/order/company")}
          >
            <StorefrontIcon />
          </IconButton>
 */}
          {identityHasProRole && (
            <>
              {!isMobile ? (
                <IconButtonWithLabel
                  icon={<StorefrontIcon />}
                  onClick={handleMarketplace}
                  label={t("power-pro-shared.p-app-bar.menu.marketplace")}
                />
              ) : (
                <IconButton color="inherit" onClick={handleMarketplace}>
                  <StorefrontIcon />
                </IconButton>
              )}
            </>
          )}

          {!isMobile ? (
            <IconButtonWithLabel
              icon={<StarIcon />}
              onClick={
                identityHasProRole ? navigateProWatchList : navigatePUIWatchList
              }
              label={t("power-pro-shared.p-app-bar.menu.watch-list")}
            />
          ) : (
            <IconButton color="inherit" onClick={navigateProWatchList}>
              <StarIcon />
            </IconButton>
          )}

          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={handleShowMenu}
          >
            <AccountCircle />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default PAppBar;
