import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Element, scroller } from "react-scroll";
import { Wizard } from "../auth/components/wizard";
import { AuthLevel2 } from "../auth/const";
import { currentIdentity, isAuthenticated } from "../auth/selectors";

const AuthCard = ({
  startHeader,
  detailsHeader = null,
  detailsRequired = false
}) => {
  const { t } = useTranslation();

  // scroll to top on state transition.
  // ensure relevant area being visible
  const onTransitAuthState = () => {
    scroller.scrollTo("prospect-contact-auth-card", {
      duration: 400,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -70
    });
  };

  return (
    <Element name="prospect-contact-auth-card">
      <Card>
        <CardContent>
          <Wizard
            authLevel={AuthLevel2}
            detailsRequired={detailsRequired === true}
            requireLastName={detailsRequired === true}
            cancelable={true}
            customStartStateHeader={startHeader}
            customDetailsStateHeader={detailsHeader}
            onTransitState={onTransitAuthState}
            forceNoPreventSignup={true}
            yourEmailAddressLabel={t(
              "referal.referrer-or-prospect-contact-auth.your-email-address-label"
            )}
          ></Wizard>
        </CardContent>
      </Card>
    </Element>
  );
};

export const AuthWrapper = ({
  wrap,
  startHeader,
  detailsHeader = null,
  detailsRequired = true,
  ...props
}) => {
  const authenticated = useSelector(isAuthenticated(AuthLevel2));
  const ownIdentity = useSelector(currentIdentity());
  const showWrapped = useMemo(
    () =>
      authenticated &&
      (detailsRequired === false ||
        (ownIdentity && ownIdentity.detailsComplete === true)),
    [authenticated, detailsRequired, ownIdentity]
  );

  return (
    <>
      {showWrapped === false && (
        <AuthCard {...{ startHeader, detailsHeader, detailsRequired }} />
      )}
      {showWrapped === true && React.createElement(wrap, { ...props })}
    </>
  );
};
