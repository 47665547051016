import { Snackbar } from "@material-ui/core";
import MUIAlert from "@material-ui/lab/Alert";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";

export const SEVERITY_INFO = "info";
export const SEVERITY_SUCCESS = "success";
export const SEVERITY_WARNING = "warning";
export const SEVERITY_ERROR = "error";

export const ACTION_UPDATE_FLASH_MESSAGE = "ACTION_UPDATE_FLASH_MESSAGE";
export const ACTION_UNSET_FLASH_MESSAGE = "ACTION_UNSET_FLASH_MESSAGE";

export const flashMessage = (message, severity) => {
  return {
    type: ACTION_UPDATE_FLASH_MESSAGE,
    message: message,
    severity: severity
  };
};

export const unsetFlashMessage = () => {
  return {
    type: ACTION_UNSET_FLASH_MESSAGE
  };
};

export const pendingFlashMessage = () => {
  return createSelector(
    state => state.flash,
    flash => flash
  );
};

const initialState = {
  present: false
};

export default function flashReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_UPDATE_FLASH_MESSAGE:
      return {
        present: true,
        message: action.message,
        severity: action.severity
      };
    case ACTION_UNSET_FLASH_MESSAGE:
      return {
        present: false
      };
    default:
      return state;
  }
}

export const Flash = props => {
  const flash = useSelector(pendingFlashMessage());
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(unsetFlashMessage());
  };

  if (flash.present) {
    // try to get error message by status
    const translatedMessage = t(flash.message);

    return (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={flash.present}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <MUIAlert
          onClose={handleClose}
          variant="filled"
          severity={flash.severity}
        >
          {translatedMessage}
        </MUIAlert>
      </Snackbar>
    );
  } else {
    return <></>;
  }
};
