export const STATE_UNSET = "unset";
export const STATE_START = "start";
export const STATE_CONFIRM_IDENTITY = "confirmIdentity";
export const STATE_DETAILS = "details";
export const STATE_OTP = "otp";
export const STATE_OTP_LIMIT_EXCEEDED = "otpLimitExc";
export const STATE_PASSWORD = "password";
export const STATE_FINISHED = "finished";
export const STATE_RESET_PASSWORD = "resetPwd";

export const STATE_OTP_SCENARIO_AUTH = 0;
export const STATE_OTP_SCENARIO_RESET_PWD = 1;
export const STATE_OTP_SCENARIO_INITIAL_PWD = 2;

export const PREF_IDENTITY_LANGUAGE = "preferredLang";
