import React from "react";
import { Route } from "react-router-dom";
import {
  Authentication,
  MagicLink,
  EmailInitialValidation,
  EmailChangeValidation
} from "../../auth/pages";

export const AuthRoutes = () => {
  return (
    <>
      <Route path="/auth" exact component={Authentication} />

      <Route
        path="/auth/email-initial-validation/:b64"
        exact
        component={EmailInitialValidation}
      />

      <Route
        path="/auth/email-change-validation/:b64"
        exact
        component={EmailChangeValidation}
      />

      {/* LEGACY BEGIN */}
      <Route
        path="/auth/email-confirmation/:b64"
        exact
        component={EmailInitialValidation}
      />
      {/* LEGACY END */}

      <Route path="/magic-link/:jwt" exact component={MagicLink} />
    </>
  );
};
