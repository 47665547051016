import {
  ACTION_UPDATE_LOADING_STATUS,
  STATUS_STARTED,
  ACTION_SET_DISABLE_BACKDROP_CLICK_HINT
} from "./actions";

export const initialState = {
  contexts: []
};

export default function loading(state = initialState, action) {
  switch (action.type) {
    case ACTION_SET_DISABLE_BACKDROP_CLICK_HINT:
      return {
        ...state,
        disableBackdropClickHint: action.value
      };
    case ACTION_UPDATE_LOADING_STATUS:
      return {
        contexts:
          action.status === STATUS_STARTED
            ? [...state.contexts, action.context]
            : state.contexts.filter(e => e !== action.context)
      };
    default:
      return state;
  }
}
