import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { ErrorCard } from "../../generics/components";

export const JobPastDeadline = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("referral.pages.job-past-deadline.window-title")}</title>
      </Helmet>
      <ErrorCard
        title="referral.pages.job-past-deadline.title"
        text="referral.pages.job-past-deadline.text"
      />
    </>
  );
};
