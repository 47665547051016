import { CardHeader, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { scroller } from "react-scroll";

const useLocalStyles = makeStyles(theme => ({
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  }
}));

export const JobDetailsCardHeader = ({ jca }) => {
  const classes = useLocalStyles();
  const { t } = useTranslation();

  const bull = <span className={classes.bullet}> </span>;
  const scrollToDetails = () => {
    scroller.scrollTo("job-details-start", {
      duration: 900,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -30
    });
  };

  const headerClickBehaviour = {
    onClick: scrollToDetails,
    style: { cursor: "pointer" }
  };

  return (
    <CardHeader
      title={
        <React.Fragment>
          <Typography variant="h6" color="primary" {...headerClickBehaviour}>
            {t(
              "referal.referrer-or-prospect-job-details-card.card-header.header-caption",
              { jca }
            )}
          </Typography>
          <Typography variant="h5" {...headerClickBehaviour}>
            {t(
              "referal.referrer-or-prospect-job-details-card.card-header.header",
              { job: jca.job }
            )}
          </Typography>
        </React.Fragment>
      }
      subheader={
        <Typography component="span" variant="body2" {...headerClickBehaviour}>
          {jca.job.subTitle}
          {bull}
          {jca.job.location}
          {bull}
          {jca.job.type}
        </Typography>
      }
    />
  );
};
