import { default as Axios } from "axios";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AuthLevel1, AuthLevel2 } from "../auth/const";
import { isAuthenticated } from "../auth/selectors";
import config, { apiURL } from "../config";
import { cachingAxios } from "../generics/caching-axios";
import { defaultErrorBehaviour } from "../generics/errors/actions";
import * as loadingActions from "../generics/loading/actions";
import { DOMAIN_JOB_LOADING_CONTEXT, storeJCA, storeJCAs } from "./actions";
import { ErrJobPastDeadline } from "./errors";
import { selectJCAByToken } from "./selectors";
import i18n from "i18next";
import dot from "dot-object";

export const useJCAFromToken = token => {
  const authenticated = useSelector(isAuthenticated(AuthLevel1));
  const dispatch = useDispatch();
  const jca = useSelector(selectJCAByToken(token));
  const history = useHistory();

  useEffect(() => {
    const asyncRequestJCA = async () => {
      // determine wether we use axios caching or not
      const http = cachingAxios();

      dispatch(
        loadingActions.updateLoadingStatus(
          loadingActions.STATUS_STARTED,
          DOMAIN_JOB_LOADING_CONTEXT
        )
      );

      try {
        var url = apiURL(`/rt-jv/${token}`);

        if (authenticated === true) {
          url = apiURL(`/rt-jv/${token}/mem`);
        }

        const res = await http.get(url);

        // FIXME: following is a bit hacky. find a better solution. problem is that retree roots don't have a source token
        if (!res.data.association.sourceToken) {
          console.log(
            "useJCAFromToken|asyncRequestJCA - using customToken as sourceToken since sourceToken == null (retree root)"
          );
          res.data.association.sourceToken = res.data.association.customToken;
        }

        if (res.data.association.sourceToken.token !== token) {
          res.data.association.sourceToken.originalToken = token;
        }

        if (res.data?.meta?.uiTexts) {
          res.data.meta.uiTexts.forEach(x => {
            i18n.addResourceBundle(
              x.language,
              "translation",
              dot.object({ [x.key]: x.value }),
              true,
              true
            );
          });
        }

        dispatch(storeJCA(res.data));

        console.log(`useJCAFromToken x ${token} => `, res.data);
      } catch (error) {
        const action = defaultErrorBehaviour(error, "job.jobAction.error");

        if (action.message === ErrJobPastDeadline) {
          // forward to error screen if job is past deadline
          history.push(config.jobPastDeadlineRoute);
        } else {
          dispatch(action);
        }
      } finally {
        dispatch(
          loadingActions.updateLoadingStatus(
            loadingActions.STATUS_FINISHED,
            DOMAIN_JOB_LOADING_CONTEXT
          )
        );
      }
    };

    if (!jca) {
      // => no JCA yet => request it!
      console.log("useJCAFromToken JCA N/A => asyncRequestJCA");
      asyncRequestJCA();
      return;
    }

    if (authenticated === true && !jca.association.customToken) {
      // => we are authenticated but JCA doesn't containg a custom token
      // => request it because we should get it
      console.log(
        "useJCAFromToken JCA | auth==true && jca.association.customToken==null => asyncRequestJCA"
      );
      asyncRequestJCA();
      return;
    }

    // => JCA is there and state OK => do nothing!
  }, [authenticated, dispatch, token, jca, history]);

  return jca;
};

export const usePositionInReferralChain = jca => {
  return useMemo(
    () => ({
      pos:
        jca?.association?.node?.pos || jca?.association?.sourceToken?.pos || 0,
      max: jca?.job?.maxChainLen
    }),
    [jca]
  );
};

// FIXME: resurrect
// export const useJCAOnDistinctURI = (jca, currentShareToken) => {
//   const history = useHistory();
//
//   useEffect(() => {
//     if (!jca) {
//       return;
//     }
//
//     const desiredToken = jca.association.customToken
//       ? jca.association.customToken.token
//       : jca.association.sourceToken.token;
//
//     if (currentShareToken === desiredToken) {
//       return;
//     }
//
//     history.push("/job/" + desiredToken);
//   }, [jca, history, currentShareToken]);
// };
//

export const useSourceIntention = jca => {
  const intention = useMemo(
    () =>
      jca &&
      jca.association.sourceToken &&
      jca.association.sourceToken.intention,
    [jca]
  );
  return intention;
};

// useAllAvailableJCAs will retrieve all jobs from backend
export const useAllAvailableJCAs = () => {
  const dispatch = useDispatch();
  const authenticated = useSelector(isAuthenticated(AuthLevel2));

  useEffect(() => {
    const apiCall = async () => {
      try {
        try {
          const res = await Axios.get(apiURL(`/rt-jv`));

          dispatch(storeJCAs(res.data));

          console.log(`useAllAvailableJCAs useEffect apiCall => `, res.data);
        } catch (error) {
          dispatch(defaultErrorBehaviour(error, "job.jobAction.error"));
        } finally {
          dispatch(
            loadingActions.updateLoadingStatus(
              loadingActions.STATUS_FINISHED,
              DOMAIN_JOB_LOADING_CONTEXT
            )
          );
        }
      } catch (e) {
        console.log("useAllAvailableJCAs#useEffect#api =>", e);
      }
    };

    if (authenticated === true) {
      apiCall();
    }
  }, [dispatch, authenticated]);
};
