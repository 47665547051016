export const ACTION_ERROR_OCCURED = "ACTION_ERROR_OCCURED";
export const ACTION_ERROR_RESOLVED = "ACTION_ERROR_RESOLVED";
export const ACTION_ERROR_HIDE = "ACTION_ERROR_HIDE";
export const TYPE_INTERFACE_ERROR = "ie";
export const TYPE_UNKNOWN_ERROR = "ue";

export const defaultErrorBehaviour = (error, i18nPrefix = "global.error") => {
  if (error.response) {
    return {
      type: ACTION_ERROR_OCCURED,
      errorType: TYPE_INTERFACE_ERROR,
      status: error.response.status,
      message: error.response.data.message,
      i18n: i18nPrefix
    };
  }

  console.log("defaultErrorBehaviour | unknown error: ", error);

  return {
    type: ACTION_ERROR_OCCURED,
    errorType: TYPE_UNKNOWN_ERROR,
    message: "" + error
  };
};

export const resolveError = () => {
  return {
    type: ACTION_ERROR_RESOLVED
  };
};

export const hideError = () => {
  return {
    type: ACTION_ERROR_HIDE
  };
};
