// errorMessage returns structured error message or
// null if there's no structured message
export const errorMessage = error => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    return error.response.data.message;
  }

  return null;
};
