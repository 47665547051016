import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(3)
    }
  },
  stepper: {
    padding: theme.spacing(3, 0, 5)
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1)
  },
  identity: {
    inlineContent: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "space-between"
    }
  },
  detailsCard: {
    width: "100%"
  },
  browserLogo: {
    width: 40,
    height: 40
  },
  appLogo: {
    width: 100
  },
  focusAvatar: {
    backgroundColor: theme.palette.primary.main + " !important"
  },
  cardContentWithCustomActions: {
    paddingBottom: "0 !important"
  },
  noSpacing: {
    "& p": {
      marginTop: 0,
      marginBottom: 0
    }
  },
  belowAppBar: {
    marginTop: theme.spacing(12)
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));
