// main configuration place.
// inspired by https://serverless-stack.com/chapters/environments-in-create-react-app.html

let defaults = {
  website: "https://block2job.com",
  someKey: "someValue",
  ui: {
    smallScreenBreakpoint: "xs"
  },
  legalLinks: {
    termsOfUse: "https://block2job.com/nutzungsbedingungen/",
    privacyDeclaration: "https://block2job.com/datenschutzrichtlinie/"
  },
  salt: "0ytK1A1LBwrAAMqCXRTBYqZW2dWdy_VVAALd7sAk]FUy+sRwZP1gy]i^J7n,OJ",
  assets: {
    logo: `/logo.png`,
    background: `/hint.png`,
    browsers: {
      Firefox: "/firefox.png",
      Chrome: "/chrome.png",
      Safari: "/safari.png",
      Opera: "/opera.png",
      Edge: "/edge.png"
    }
  },
  enabledLoadingBackdrop: false,
  enableAxiosCache: true,
  axiosCacheMaxAge: 5 * 60 * 1000, // <=> 5 min
  apiURL: process.env.REACT_APP_API_URL || "",
  webAppURL: process.env.REACT_APP_WEBAPP_URL || "",
  defaultRoute: "/",

  defaultProRoute: "/pro/watch-list",
  defaultPUIRoute: "/pui/watch-list",
  proWatchListRoute: "/pro/watch-list",
  puiWatchListRoute: "/pui/watch-list",
  jobPastDeadlineRoute: "/job-past-deadline",

  // supported countries and terms of use
  supportedCountriesFavorites: ["DE", "CH", "AT", "XX"],
  defaultCountry: "DE",

  supportedCurrencies: ["EUR", "CHF", "BTC", "ADA", "ETH"],
  defaultCurrency: "EUR",

  // i18n stuff
  fallbackLanguage: "en",
  defaultLanguage: "de",

  requiredIdentityDetails: {
    country: false
  },

  termsOfUse: "https://block2job.com/nutzungsbedingungen",
  privacyStatements: "https://block2job.com/datenschutz/"
};

let local = {};

let cloud = {};

let config = {
  ...defaults,
  ...(process.env.REACT_APP_STAGE === "cloud" ? cloud : local)
};

function withoutSlashSuffix(str) {
  if (str[str.length - 1] === "/") {
    return str.substr(0, str.length - 1);
  }
  return str;
}

export function apiURL(path) {
  return withoutSlashSuffix(config.apiURL) + "/v1" + path;
}

export function webAppURL(path) {
  return withoutSlashSuffix(config.webAppURL) + path;
}

export function jobURL(token) {
  return webAppURL("/" + token);
}

export default config;
