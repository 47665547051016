import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { CardText } from "../../generics/components/card-text";
import { ReferalCardGrid } from "../components/referal-card-grid";
import { ReferalCardGridItem } from "../components/referal-card-item";
import { ReferrerOrProspectJobDetailsCard } from "../components/referrer-or-prospect-job-details-card";
import { ReferrerOrProspectReferrerShareCard } from "../components/referrer-or-prospect-referrer-share-card";
import { AuthWrapper } from "./auth-wrapper";

const AuthHeader = () => {
  const { t } = useTranslation();
  return (
    <>
      <CardText>{t("referal.auth-wizard-start-headers.refer-job")}</CardText>
    </>
  );
};

const InnerPage = ({ jca }) => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>
          {t("pages.expected-referrer-or-prospect-scenario.title", {
            jca: jca
          })}
        </title>
      </Helmet>

      <ReferalCardGrid>
        <ReferalCardGridItem>
          <ReferrerOrProspectReferrerShareCard jca={jca} />
        </ReferalCardGridItem>

        <ReferalCardGridItem>
          <ReferrerOrProspectJobDetailsCard jca={jca} />
        </ReferalCardGridItem>
      </ReferalCardGrid>
    </>
  );
};

export const ReferralSharePage = () => {
  return (
    <AuthWrapper wrap={InnerPage} startHeader={<AuthHeader />}></AuthWrapper>
  );
};
