import { CircularProgress } from "@material-ui/core";
import { default as Axios, default as axios } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { apiURL } from "../../config";
import { useAllAvailableJCAs } from "../../domain/hooks";
import { selectJCAs } from "../../domain/selectors";
import { defaultErrorBehaviour } from "../../generics/errors/actions";
import { flashMessage } from "../../generics/flash";
import * as authActions from "../actions";
import { AuthLevel2 } from "../const";
import { isAuthenticated } from "../selectors";

const ValidateEmail = ({ initialValidation }) => {
  // encoded code to use for resetting password
  const { b64 } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [emailValidated, setEmailValidate] = useState(false);

  // will become true when authentication succeeded
  // this might happen either if we have already been authenticated
  // or we got authenticated using our b64 here
  const authenticated = useSelector(isAuthenticated(AuthLevel2));

  // jcas will load only if we are authenticated.
  // once authenticated, retrieve ist of all JCAs so that we can
  // show job of first JCA available here
  useAllAvailableJCAs();
  const jcas = useSelector(selectJCAs());

  // performs actual email address verification
  const submit = useCallback(async () => {
    try {
      // first verify email address
      await Axios.patch(apiURL("/identity/_/verification/" + b64));

      // tell user it worked!
      dispatch(flashMessage("auth.validateEmail.success", "success"));

      setEmailValidate(true);
    } catch (error) {
      const action = defaultErrorBehaviour(error, "auth.validateEmail.error");
      if (423 === action.status) {
        // <=> 423 LOCKED returned if JWT for confirmation was already used
        // meaning email already confirmed so we can show corret message.
        // https://block2job.atlassian.net/browse/MS-198
        dispatch(flashMessage("auth.validateEmail.success", "success"));
        setEmailValidate(true);
      } else {
        dispatch(action);
      }
    }
  }, [b64, dispatch]);

  // in case we are not authenticated, this effect will use the b64 in order to
  // retrieve a new fresh JWT which then will be used for authentication
  useEffect(() => {
    if (emailValidated === true && authenticated === false) {
      // try to authenticate using email verification claim if not authenticated, yet
      (async () => {
        try {
          // refresh token. this will refresh and validate in one
          const res = await axios.put(
            apiURL("/auth"),
            {},
            {
              headers: { Authorization: b64 }
            }
          );

          dispatch(authActions.fireAuthenticated(res.data.token));
        } catch (error) {
          // failed refreshing the token. forget about stored authentication
          dispatch(authActions.fireResetAuthentication());
        }
      })();
    }
  }, [authenticated, emailValidated, b64, dispatch]);

  // this effect will redirect to any job in list of all JCAs so that
  // we will show a job (which in 99.99% cases can only be exactly one)
  // instead of a blank page
  useEffect(() => {
    if (emailValidated === false) {
      return;
    }
    if (initialValidation === true) {
      if (jcas && jcas.length > 0) {
        history.push(`/${jcas[0].association.customToken.token}`);
      }
    } else {
      history.push(`/identity`);
    }
  }, [jcas, history, emailValidated, initialValidation]);

  useEffect(() => {
    submit();
  }, [submit]);

  return (
    <>
      <CircularProgress />
    </>
  );
};

export default ValidateEmail;
