import CryptoJS from "crypto-js";
import config from "../config";

// hashPassword before sending to backend
export const hashPassword = password => {
  const salt = config.salt;
  let hasher = CryptoJS.algo.SHA256.create();
  hasher.update(password, "utf-8");
  hasher.update(salt, "utf-8");
  return hasher.finalize().toString(CryptoJS.enc.Base64);
};
