import { Typography } from "@material-ui/core";
import React from "react";
import ReactMarkdown from "react-markdown";
import { useStyles } from "../../theme/classes";

export const CardText = ({ variant, children }) => {
  const classes = useStyles();

  const mappedVariant = variant === "emphasized" ? "body1" : "body2";

  return (
    <Typography variant={mappedVariant} component="span">
      <ReactMarkdown
        className={classes.noSpacing}
        source={children}
      ></ReactMarkdown>
    </Typography>
  );
};
