import React from "react";
import { Route } from "react-router-dom";
import { ApplicationFeedbackPage } from "../../referal/pages/appl-feedback-page";
import { ApplyPage } from "../../referal/pages/contact-page";
import { JobPastDeadline } from "../../referal/pages/job-past-deadline";
import { ReferralPrintPage } from "../../referal/pages/print-page";
import { ActivateReminderPage } from "../../referal/pages/reminder-page";
import { ReferralSharePage } from "../../referal/pages/share-page";
import { ReferralStartPage } from "../../referal/pages/start-page";
import { TippPage } from "../../referal/pages/tipp-page";

const Routes = () => (
  <>
    {/* IT IS VERY(!) IMPORTANT THAT THIS ONE IS THE LAST ROUTE DECLARED! */}
    <Route path="/:id([A-Za-z0-9]{6})" exact component={ReferralStartPage} />
    <Route
      path="/:id([A-Za-z0-9]{6})/print"
      exact
      component={ReferralPrintPage}
    />
    <Route
      path="/:id([A-Za-z0-9]{6})/share"
      exact
      component={ReferralSharePage}
    />
    <Route path="/:id([A-Za-z0-9]{6})/apply" exact component={ApplyPage} />
    <Route
      path="/:id([A-Za-z0-9]{6})/reminder"
      exact
      component={ActivateReminderPage}
    />
    <Route path="/:id([A-Za-z0-9]{6})/tipp" exact component={TippPage} />
    <Route
      path="/:id([A-Za-z0-9]{6})/appl-feedback/:param([A-Za-z0-9\-]{0,24})"
      exact
      component={ApplicationFeedbackPage}
    />
    <Route path="/job-past-deadline" exact component={JobPastDeadline} />
  </>
);

export { Routes as JobRoutes };
