import { Box } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { currentIdentity } from "../auth/selectors";
import { PAppBar } from "../power-pro-shared/p-app-bar";
import { useStyles } from "../theme/classes";
import { DetailsView, EditEmail, EditPersonalDetails } from "./components";
import {
  STATE_EDIT_DETAILS,
  STATE_EDIT_EMAIL,
  STATE_VIEW,
  STATE_WAITING
} from "./constants";

export const IdentityDetailsPage = () => {
  const classes = useStyles();
  const [state, setState] = useState(STATE_WAITING);
  const self = useSelector(currentIdentity());

  // state transition func
  const transit = useCallback(state => {
    setState(state);
  }, []);

  // transit waiting to view if self available
  useEffect(() => {
    if (!!self && state === STATE_WAITING) {
      transit(STATE_VIEW);
    }
  }, [self, state, transit]);

  const Component = props => {
    switch (state) {
      case STATE_WAITING:
        return <div {...props} data-testid="state-waiting"></div>;
      case STATE_VIEW:
        return <DetailsView {...props} self={self} transit={transit} />;
      case STATE_EDIT_EMAIL:
        return <EditEmail {...props} self={self} transit={transit} />;
      case STATE_EDIT_DETAILS:
        return <EditPersonalDetails {...props} self={self} transit={transit} />;
      default:
        return <div {...props} data-testid="state-default"></div>;
    }
  };

  return (
    <>
      <PAppBar />

      <Box className={classes.belowAppBar} />

      <Component />
    </>
  );
};
