import Axios from "axios";
import { apiURL } from "../../config";
import { storeJCA } from "../../domain/actions";
import { defaultErrorBehaviour } from "../../generics/errors/actions";

export const ACTION_PROUI_STORE_MARKETPLACE_JOBS =
  "ACTION_PROUI_STORE_MARKETPLACE_JOBS";

export const storeMarketplaceJobs = jcs => {
  return {
    type: ACTION_PROUI_STORE_MARKETPLACE_JOBS,
    jcs
  };
};

export const fireJoinRetreeByID = id => {
  return async dispatch => {
    try {
      const res = await Axios.post(apiURL(`/pro/rt/${id}`));

      dispatch(storeJCA(res.data));
    } catch (error) {
      dispatch(defaultErrorBehaviour(error));
    }
  };
};
