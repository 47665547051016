import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Element, scroller } from "react-scroll";
import { Wizard } from "../../auth/components/wizard";
import { AuthLevel1 } from "../../auth/const";
import { currentIdentity, isAuthenticated } from "../../auth/selectors";
import { useJCAFromToken } from "../../domain/hooks";

const AuthCard = ({
  startHeader,
  detailsHeader = null,
  detailsRequired = false
}) => {
  const { t } = useTranslation();

  // scroll to top on state transition.
  // ensure relevant area being visible
  const onTransitAuthState = () => {
    scroller.scrollTo("prospect-contact-auth-card", {
      duration: 400,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -70
    });
  };

  return (
    <Element name="prospect-contact-auth-card">
      <Card>
        <CardContent>
          <Wizard
            authLevel={AuthLevel1}
            detailsRequired={detailsRequired === true}
            requireLastName={detailsRequired === true}
            cancelable={true}
            customStartStateHeader={startHeader}
            customDetailsStateHeader={detailsHeader}
            onTransitState={onTransitAuthState}
            yourEmailAddressLabel={t(
              "referal.referrer-or-prospect-referrer-auth.your-email-address-label"
            )}
          ></Wizard>
        </CardContent>
      </Card>
    </Element>
  );
};

export const AuthWrapper = ({
  wrap,
  startHeader,
  detailsHeader = null,
  detailsRequired = false,
  ...props
}) => {
  const authenticated = useSelector(isAuthenticated(AuthLevel1));
  const { id } = useParams();
  const jca = useJCAFromToken(id);
  const ownIdentity = useSelector(currentIdentity());
  const showWrapped = useMemo(
    () =>
      authenticated &&
      !!jca &&
      (detailsRequired === false ||
        (ownIdentity && ownIdentity.detailsComplete === true)),
    [authenticated, detailsRequired, jca, ownIdentity]
  );

  return (
    <>
      {showWrapped === false && (
        <AuthCard {...{ startHeader, detailsHeader, detailsRequired }} />
      )}
      {showWrapped === true && React.createElement(wrap, { jca, ...props })}
    </>
  );
};
