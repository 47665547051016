import { CardHeader, Grid, Typography } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React from "react";
import { useTranslation } from "react-i18next";
import { ShareCardInlay } from "../share-card-inlay";

const ReferrerOrProspectReferrerShareCard = ({ jca }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h5">
            {t(
              "referal.referrer-or-prospect-referrer-share-card.card-header.header"
            )}
          </Typography>
        }
      />

      <CardContent>
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            <ShareCardInlay jca={jca} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ReferrerOrProspectReferrerShareCard;
