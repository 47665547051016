import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { apiURL } from "../../../config";
import { defaultErrorBehaviour } from "../../../generics/errors/actions";
import * as validators from "../../../generics/validators";
import * as actions from "../../actions";
import { hashPassword } from "../../helpers";
import { useStyles } from "../styles";
import {
  STATE_DETAILS,
  STATE_OTP,
  STATE_OTP_SCENARIO_RESET_PWD
} from "./constants";
import { StateLegalLinks } from "./state-legal-links";
import { WizardTitle } from "./wizard-title";

export const StatePassword = ({
  transitState,
  sharedState,
  setSharedState
}) => {
  // DTO for registration
  const initialDTO = {
    email: sharedState.email,
    password: ""
  };

  const [dto, setDTO] = useState(initialDTO);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(
    validators.deriveNoErrorsObj(initialDTO)
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // submit form and process result
  const submit = async event => {
    // everything valid - create identity!
    try {
      setLoading(true);
      const tokenResponse = await axios.post(apiURL("/auth/password"), {
        ...dto,
        password: dto.password.length > 0 ? hashPassword(dto.password) : null
      });
      dispatch(actions.fireAuthenticated(tokenResponse.data.token));

      // remember that user confirmed his identity in this session
      dispatch(actions.flagAuthenticatedInSession());

      transitState(STATE_DETAILS);
    } catch (error) {
      dispatch(defaultErrorBehaviour(error));
    } finally {
      setLoading(false);
    }
  };

  const requestPasswordReset = async () => {
    // everything valid - create identity!
    try {
      setLoading(true);
      // otp will be sent to user.
      setSharedState({
        ...sharedState,
        otpScenario: STATE_OTP_SCENARIO_RESET_PWD
      });
      transitState(STATE_OTP);
    } catch (error) {
      dispatch(defaultErrorBehaviour(error));
    } finally {
      setLoading(false);
    }
  };

  // handleForm validation etc. calls submit when there are no errors
  const handleForm = async event => {
    event.preventDefault();
    event.stopPropagation();

    const errs = {
      password: validators.isEmpty(dto.password)
    };
    setErrors(errs);

    if (validators.hasNoError(errs)) {
      submit();
    }
  };

  const updateDTO = value => {
    setDTO({ ...dto, password: value });
  };

  return (
    <>
      <div data-testid="state-password">
        <WizardTitle
          title={t("auth.password.title")}
          text={t("auth.password.description")}
        />
        <form className={classes.form} onSubmit={handleForm} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                error={errors.password}
                disabled={loading}
                variant="outlined"
                type="password"
                required
                fullWidth
                id="password"
                label={t("generic.word.password")}
                name="password"
                autoComplete="password"
                inputProps={{
                  "data-testid": "input-password"
                }}
                helperText={errors.password && t("auth.password.validation")}
                onChange={e => updateDTO(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Button
                disabled={loading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                data-testid="input-submit"
                className={classes.submit}
              >
                {t("generic.word.next")}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                disabled={loading}
                fullWidth
                color="primary"
                data-testid="button-resetpwd"
                className={classes.altSubmit}
                onClick={requestPasswordReset}
              >
                {t("auth.password.reset-button")}
              </Button>
            </Grid>
          </Grid>
        </form>

        <StateLegalLinks />
      </div>
    </>
  );
};
