import React, { useState } from "react";
import { Element, scroller } from "react-scroll";

export const OrderContext = React.createContext({
  container: {},
  setContainer: () => {},
  company: {},
  setCompany: {},
  scrollToTop: () => {}
});

//export const initialContainerDTO = {
//  title: "Titel",
//  subTitle: "Untertitel",
//  type: "Typ",
//  location: "Braunschweig",
//  description: "Lorem Ipsum",
//  findersFee: 1000,
//  findersFeeCurrency: "EUR"
//};

// export const initialCompanyDTO = {
//   companyName: "Titel Company",
//   street: "Finkenweg",
//   zip: "Typ",
//   city: "Braunschweig",
//   country: "DE",
//   publicDescription: "1000"
// };

export const initialContainerDTO = {
  title: "",
  subTitle: "",
  type: "",
  location: "",
  description: "",
  findersFee: 0,
  findersFeeCurrency: "EUR",
  findersFeeAltText: ""
};

export const initialContainerFreeEdition = {
  title: "",
  subTitle: "",
  type: "",
  location: "",
  description: "",
  findersFee: "",
  findersFeeCurrency: "EUR"
};

export const initialCompanyDTO = {
  companyName: "",
  street: "",
  zip: "",
  city: "",
  country: "DE",
  publicDescription: ""
};

export const OrderContextWrapper = ({ children }) => {
  const scrollToTop = () => {
    scroller.scrollTo("top-element", {
      duration: 500,
      delay: 0,
      smooth: "easeInOutQuart"
    });
  };
  const [container, setContainer] = useState(initialContainerDTO);
  const [company, setCompany] = useState(initialCompanyDTO);
  const contextValue = {
    container,
    setContainer,
    company,
    setCompany,
    scrollToTop
  };

  return (
    <Element name="top-element">
      <OrderContext.Provider value={contextValue}>
        {children}
      </OrderContext.Provider>
    </Element>
  );
};
