import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthLevel2 } from "../../auth/const";
import { isAuthenticated } from "../../auth/selectors";
import { apiURL } from "../../config";
import { cachingAxios } from "../../generics/caching-axios";
import { defaultErrorBehaviour } from "../../generics/errors/actions";
import { storeMarketplaceJobs } from "../actions";

export const useMarketplaceJobs = () => {
  const dispatch = useDispatch();
  const authenticated = useSelector(isAuthenticated(AuthLevel2));

  useEffect(() => {
    const apiCall = async () => {
      try {
        try {
          // determine wether we use axios caching or not
          const res = await cachingAxios().get(apiURL(`/pro/jobs`));

          dispatch(storeMarketplaceJobs(res.data));

          console.log(`useMarketplaceJobs useEffect apiCall => `, res.data);
        } catch (error) {
          dispatch(defaultErrorBehaviour(error, "job.jobAction.error"));
        }
      } catch (e) {
        console.log("useMarketplaceJobs#useEffect#api =>", e);
      }
    };

    if (authenticated === true) {
      apiCall();
    }
  }, [dispatch, authenticated]);
};
