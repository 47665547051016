import { Box } from "@material-ui/core";
import React from "react";

/**
 * CenteredInCard wrapper to let stuff appear centered in card
 */
export const CenteredInCard = ({ children, ...props }) => {
  return (
    <Box display="flex">
      <Box m="auto">{children}</Box>
    </Box>
  );
};
