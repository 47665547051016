import { Box } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { usePositionInReferralChain } from "../../../domain/hooks";
import { CardText } from "../../../generics/components/card-text";
import { useStyles } from "../../../theme/classes";
import { FindersFeePanel } from "../findersfee-panel";
import { JobDetailsCardHeader } from "../job-details-card-header";
import ActionButtons from "./action-buttons";

const ReferrerOrProspectReferrerStartCard = ({
  jca,
  onRefer,
  onMakeContact,
  onTipp
}) => {
  const styles = useStyles();

  const { t } = useTranslation();

  const chainPosition = usePositionInReferralChain(jca);
  const notChainLenReached = useMemo(
    () => chainPosition.max <= 0 || chainPosition.pos < chainPosition.max,
    [chainPosition]
  );

  return (
    <Card>
      <JobDetailsCardHeader jca={jca} />

      <CardContent className={styles.cardContentWithCustomActions}>
        <Box pt={1}></Box>
        {notChainLenReached && <FindersFeePanel {...{ jca }} />}
        {!notChainLenReached && (
          <CardText>
            {t(
              "referal.referrer-or-prospect-referrer-start-card.card-content.max-chain-len-reached-body",
              {
                findersFee: jca.job.findersFee,
                findersFeeCurrency: jca.job.findersFeeCurrency
              }
            )}
          </CardText>
        )}

        <ActionButtons
          jca={jca}
          onRefer={onRefer}
          onTipp={onTipp}
          onMakeContact={onMakeContact}
        />
      </CardContent>
    </Card>
  );
};

export default ReferrerOrProspectReferrerStartCard;
