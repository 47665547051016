import { Alert } from "@material-ui/lab";
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Element } from "react-scroll";
import { useJCAFromToken } from "../../domain/hooks";
import { PlatformADCard } from "../components/platform-ad-card";
import { ReferalCardGrid } from "../components/referal-card-grid";
import { ReferalCardGridItem } from "../components/referal-card-item";
import { ReferrerOrProspectJobDetailsCard } from "../components/referrer-or-prospect-job-details-card";
import ReferrerOrProspectReferrerStartCard from "../components/referrer-or-prospect-referrer-start-card/referrer-scenario-start-card";

export const ReferralStartPage = props => {
  const { t } = useTranslation();
  const shareToken = props.match.params.id;
  const jca = useJCAFromToken(shareToken);
  const history = useHistory();

  // TODO: log interaction!!
  const transitShare = () => {
    history.push(`/${shareToken}/share`);
  };
  const transitApply = () => {
    history.push(`/${shareToken}/apply`);
  };
  const transitActivateReminder = () => {
    history.push(`/${shareToken}/reminder`);
  };
  const transitTipp = () => {
    history.push(`/${shareToken}/tipp`);
  };

  if (!jca) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <title>
          {t("pages.expected-referrer-or-prospect-scenario.title", {
            jca: jca
          })}
        </title>
      </Helmet>

      <Element name="first-grid-item"></Element>
      {!jca.job.customerApproved && (
        <Alert severity="warning">
          {t(
            "pages.expected-referrer-or-prospect-scenario.job-not-customer-approved"
          )}
        </Alert>
      )}

      <ReferalCardGrid>
        <ReferalCardGridItem>
          <ReferrerOrProspectReferrerStartCard
            onRefer={transitShare}
            onMakeContact={transitApply}
            onActivateReminder={transitActivateReminder}
            onTipp={transitTipp}
            jca={jca}
          />
        </ReferalCardGridItem>

        <ReferalCardGridItem>
          <ReferrerOrProspectJobDetailsCard
            jca={jca}
            fullHeader={false}
            onRefer={transitShare}
            onMakeContact={transitApply}
            onTipp={transitTipp}
          />
        </ReferalCardGridItem>

        <ReferalCardGridItem>
          <PlatformADCard />
        </ReferalCardGridItem>
      </ReferalCardGrid>
    </>
  );
};
