import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { CardText } from "../../../generics/components/card-text";

const FindersFeeExplanationModal = ({ open, onClose, jca }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="simple-dialog-title">
      <DialogTitle id="simple-dialog-title">
        {t("referal.finders-fee-explanation-modal.title")}
      </DialogTitle>
      <DialogContent>
        <CardText>
          {t("referal.finders-fee-explanation-modal.body", { jca })}
        </CardText>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          variant="contained"
          onClick={onClose}
          color="secondary"
        >
          {t("referal.finders-fee-explanation-modal.button-confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FindersFeeExplanationModal;
