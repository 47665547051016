import { Fade } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from "react";

const ReferalCardGridItem = ({ children }) => {
  return (
    <Fade in={true}>
      <Grid item>{children}</Grid>
    </Fade>
  );
};

export default ReferalCardGridItem;
