import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { CardText } from "../../../generics/components/card-text";

export const WizardTitle = ({ title, text = null }) => {
  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <Typography variant="h5">{title}</Typography>
      </Grid>
      {text && (
        <Grid item>
          <CardText>{text}</CardText>
        </Grid>
      )}
    </Grid>
  );
};
