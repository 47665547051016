import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  }
}));

const ReferalCardGrid = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3} direction="column">
        {children}
      </Grid>
    </div>
  );
};

export default ReferalCardGrid;
