import { Avatar, CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { default as React, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaEnvelope } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { apiURL } from "../../../config";
import { storeJCA } from "../../../domain/actions";
import { CardText } from "../../../generics/components/card-text";
import { defaultErrorBehaviour } from "../../../generics/errors/actions";
import { useStyles } from "../../../theme/classes";

const ReferrerOrProspectContactConfirmCard = ({ jca }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    const f = async () => {
      if (!(jca.association.customToken && jca.association.node)) {
        console.log(
          "ReferrerOrProspectContactConfirmCard#submitContactRequest | missing customToken or Node => return",
          jca
        );
        return;
      }

      if (jca.association.node.status !== 0) {
        console.log(
          "ReferrerOrProspectContactConfirmCard#submitContactRequest | status > 0 => return"
        );
        // 0 == initial state before contact request
        return;
      }

      try {
        const res = await axios.patch(
          apiURL(`/rt-jv/${jca.association.customToken.token}/application`)
        );
        dispatch(storeJCA(res.data)); // <= update the ca
      } catch (e) {
        console.log(
          "ReferrerOrProspectContactConfirmCard#submitJobApplication => ",
          e
        );
        dispatch(defaultErrorBehaviour(e, "job.applicationAction.error"));
      }
    };
    f();
  }, [dispatch, jca]);

  const styles = useStyles();

  return (
    <Card>
      <>
        <CardHeader
          avatar={
            <Avatar className={styles.focusAvatar} aria-label="recipe">
              <FaEnvelope />
            </Avatar>
          }
          title={
            <Typography variant="h5">
              {t(
                "referal.referrer-or-prospect-contact-confirm-card.auth.card-header.card-header.header"
              )}
            </Typography>
          }
        />
        <CardContent>
          <CardText>
            {t(
              "referal.referrer-or-prospect-contact-confirm-card.auth.card-header.card-content.contact-confirmation"
            )}
          </CardText>
        </CardContent>
      </>
    </Card>
  );
};

export default ReferrerOrProspectContactConfirmCard;
