import Axios from "axios";
import copy from "clipboard-copy";
import React, { useCallback, useEffect, useState } from "react";
import { isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { apiURL, jobURL } from "../../../config";
import {
  MediumEmail,
  MediumQR,
  MediumSMS,
  MediumTelegram,
  MediumUnknown,
  MediumWhatsApp
} from "../../../domain/constants";
import { defaultErrorBehaviour } from "../../../generics/errors/actions";
import { flashMessage } from "../../../generics/flash";
import { ShareByQRCodePanel } from "./share-by-qr-code-panel";
import {
  generateShareStuff,
  useAssumedUserIntention
} from "./share-card-inlay-hooks";
import { ShareOptionsPanel } from "./share-options-panel";

const ShareCardInlay = ({ jca }) => {
  console.log("ShareCardInlay, @jca=", jca);
  const [clipboardJobLink, setClipboardJobLink] = useState(
    jca.association.customToken ? jobURL(jca.association.customToken.token) : ""
  );

  const [qRCodeSelected, setQRCodeSelected] = useState(false);
  const [jobShareLink, setJobShareLink] = useState("");

  const intention = useAssumedUserIntention(jca);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // acquireClipboardJobLink will acquire a new link for copying into clipboard
  // we have to do this before clicking on the button. copying to clipboard initiated
  // by async function causes a security error on iOS/Safari
  // BUT(!!): opening links to whatapp works asynchronously. in order to not flood
  // the system with generated tokens, we do this asynchronously after button click
  // and only on demand.
  const acquireClipboardJobLink = useCallback(() => {
    if (!jca.association.customToken) {
      console.log(
        "acquireClipboardJobLink && !jca.association.customToken => return"
      );
      return;
    }

    console.log(
      "acquireClipboardJobLink && jca.association.customToken => request"
    );

    (async () => {
      // acquire new token from server
      try {
        const res = await Axios.post(
          apiURL(`/rt-jv/${jca.association.customToken.token}`),
          {
            intention: intention,
            medium: MediumUnknown
          }
        );

        console.log(
          "ShareCardInlay#acquireClipboardJobLink: token retrieved",
          res.data
        );

        setClipboardJobLink(jobURL(res.data));
      } catch (e) {
        console.log("ShareCardInlay#acquireClipboardJobLink: ", e);
        dispatch(defaultErrorBehaviour(e));
      }
    })();
  }, [dispatch, intention, jca]);

  // "constructor"
  useEffect(() => {
    // => initially
    acquireClipboardJobLink();
  }, [intention, jca, dispatch, acquireClipboardJobLink]);

  const handleCopyLinkToClipboard = () => {
    // compile corresponding temaplte
    copy(clipboardJobLink).then(() => {
      // copy link to clipboard
      dispatch(flashMessage("generic.message.flashLinkCopied", "info"));

      // acquite new token if user wil copy again ;-)
      acquireClipboardJobLink();
    });
  };

  const smsHRef = message => {
    // based on http://blog.julianklotz.de/the-sms-uri-scheme/
    message = encodeURIComponent(message);

    if (isIOS) {
      return "sms:&body=" + message;
    } else {
      return "sms:?body=" + message;
    }
  };

  // handleShareMediumClick acquires token and shares
  const handleShareMediumClick = async medium => {
    const payload = {
      intention: intention,
      medium: medium
    };

    try {
      // acquire new token from server
      const res = await Axios.post(
        apiURL(`/rt-jv/${jca.association.customToken.token}`),
        payload
      );

      // comes back as JSON string
      const acquiredToken = res.data;

      // compile corresponding temaplte
      const compiledShareTemplates = generateShareStuff(jca, acquiredToken, t);

      switch (medium) {
        case MediumWhatsApp:
          window.location.href =
            "whatsapp://send?text=" +
            encodeURIComponent(compiledShareTemplates.message);
          break;
        case MediumEmail:
          const href =
            "mailto:?subject=" +
            encodeURIComponent(compiledShareTemplates.subject) +
            "&body=" +
            encodeURIComponent(compiledShareTemplates.message);
          window.location.href = href;
          break;
        case MediumTelegram:
          window.location.href =
            "https://t.me/share/url?url=" +
            encodeURIComponent(compiledShareTemplates.link) +
            "&text=" +
            encodeURIComponent(compiledShareTemplates.messageWithoutLink);
          break;
        case MediumSMS:
          // determine correct SMS share link http://blog.julianklotz.de/the-sms-uri-scheme/
          window.location.href = smsHRef(compiledShareTemplates.message);
          break;

        case MediumQR:
          console.log(compiledShareTemplates.link);
          setQRCodeSelected(true);
          setJobShareLink(compiledShareTemplates.link);

          break;
        default:
          break;
      }
    } catch (e) {
      console.log("ShareCardInlay#handleShareMediumClick: ", e);
      dispatch(defaultErrorBehaviour(e));
    }
  };

  return (
    <>
      {qRCodeSelected ? (
        <ShareByQRCodePanel
          jobShareLink={jobShareLink}
          onCancel={() => setQRCodeSelected(false)}
        />
      ) : (
        <ShareOptionsPanel
          handleShareMediumClick={handleShareMediumClick}
          handleCopyLinkToClipboard={handleCopyLinkToClipboard}
        />
      )}
    </>
  );
};

export default ShareCardInlay;
