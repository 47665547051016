import {
  FormControl,
  FormHelperText,
  MenuItem,
  Select
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ListSubheader from "@material-ui/core/ListSubheader";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import jwt_decode from "jwt-decode";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import config, { apiURL } from "../../../config";
import { defaultErrorBehaviour } from "../../../generics/errors/actions";
import { errorMessage } from "../../../generics/errors/helpers";
import * as validators from "../../../generics/validators";
import { selectLanguage } from "../../../i18n/redux";
import * as actions from "../../actions";
import { AuthLevel1, AuthLevel2 } from "../../const";
import { ErrPasswordAuthRequired } from "../../errors";
import { useStyles } from "../styles";
import {
  PREF_IDENTITY_LANGUAGE,
  STATE_DETAILS,
  STATE_OTP,
  STATE_OTP_SCENARIO_INITIAL_PWD,
  STATE_PASSWORD
} from "./constants";
import { LegalCompliance } from "./legal-compliance";
import { WizardTitle } from "./wizard-title";

export const StateStart = ({
  transitState,
  sharedState,
  setSharedState,
  authLevel, // required authentication level
  yourEmailAddressLabel = "",
  customStartStateHeader = null,
  forceNoPreventSignup = false,
  cancelable = false
}) => {
  // AUTH_LEVEL_1 > 1 => prevent signup, meaning no account created upon login trial
  const preventSignup = useMemo(() => {
    if (forceNoPreventSignup) return false;
    else return authLevel > AuthLevel1;
  }, [forceNoPreventSignup, authLevel]);

  // DTO for registration
  const initialDTO = {
    email: !!sharedState.email ? sharedState.email : "",
    ps: preventSignup,
    country: config.defaultCountry
  };

  const [dto, setDTO] = useState(initialDTO);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(
    validators.deriveNoErrorsObj(initialDTO)
  );
  const dispatch = useDispatch();
  const classes = useStyles();
  const isMounted = useRef(true);
  const language = useSelector(selectLanguage());
  const params = useParams();
  const shareTokenOpt = useMemo(() => params.id, [params]);
  const history = useHistory();

  // init i18n
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // submit form and process result
  const submit = useCallback(async () => {
    // everything valid - create identity!
    try {
      setLoading(true);
      const tokenResponse = await axios.post(apiURL("/identities"), {
        ...dto,
        language: language.current
      });

      // check auth level and redirect to password reset if too low
      const decoded = jwt_decode(tokenResponse.data.token);

      if ((decoded.lvl & authLevel) !== authLevel) {
        // insufficient auth level => redirect to OTP in password reset state
        setSharedState({
          ...sharedState,
          email: dto.email,
          otpScenario: STATE_OTP_SCENARIO_INITIAL_PWD
        });
        transitState(STATE_OTP);
      } else {
        // dispatch we are authenticated!
        dispatch(actions.fireAuthenticated(tokenResponse.data.token));

        // remember that user confirmed his identity in this session
        dispatch(actions.flagAuthenticatedInSession());

        // continue to user details screen
        transitState(STATE_DETAILS);

        setTimeout(async () => {
          if ((decoded.lvl & AuthLevel2) === AuthLevel2) {
            try {
              // update locale in settings
              await axios.patch(apiURL("/identity/-/pref"), {
                key: PREF_IDENTITY_LANGUAGE,
                value: language.current
              });
            } catch (e) {
              console.error(e);
            }
          }
        }, 500);
      }
    } catch (error) {
      setSharedState({ ...sharedState, email: dto.email });

      switch (errorMessage(error)) {
        case ErrPasswordAuthRequired:
          transitState(STATE_PASSWORD);
          break;
        default:
          dispatch(defaultErrorBehaviour(error));
      }
    } finally {
      if (isMounted.current) {
        setLoading(false);
      }
    }
  }, [
    language,
    authLevel,
    dispatch,
    dto,
    setSharedState,
    sharedState,
    transitState
  ]);

  // handleForm validation etc. calls submit when there are no errors
  const handleForm = event => {
    event.preventDefault();
    event.stopPropagation();

    // E3-113: don't crash on whitespace in E-Mail address
    dto.email = ("" + dto.email).trim();

    const errs = {
      email: validators.isNotEmail(dto.email)
    };
    setErrors(errs);
    if (!validators.hasError(errs)) {
      submit();
    }
  };

  const handleBack = () => {
    if (shareTokenOpt) {
      history.push(`/${shareTokenOpt}`);
    }
  };

  return (
    <div data-testid="state-start">
      {!!customStartStateHeader && customStartStateHeader}
      {!customStartStateHeader && (
        <>
          <WizardTitle title={t("auth.start.title")} />
        </>
      )}

      <form className={classes.form} onSubmit={handleForm} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              error={errors.email}
              disabled={loading}
              variant="outlined"
              fullWidth
              id="email"
              label={
                yourEmailAddressLabel === ""
                  ? t("auth.start.your-email")
                  : yourEmailAddressLabel
              }
              value={dto.email}
              name="email"
              autoComplete="email"
              inputProps={{
                "data-testid": "input-email"
              }}
              helperText={errors.email && t("auth.start.validation.email")}
              onChange={e => setDTO({ ...dto, email: e.target.value })}
            />
          </Grid>
          {config.requiredIdentityDetails.country && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Select
                  disabled={loading}
                  value={dto.country}
                  onChange={e => setDTO({ ...dto, country: e.target.value })}
                >
                  {config.supportedCountriesFavorites.map(v => (
                    <MenuItem key={v} value={v}>
                      {t("generic.country." + v)}
                    </MenuItem>
                  ))}
                  <ListSubheader>
                    {t("auth.start.other-countries")}
                  </ListSubheader>
                  {config.supportedCountries.map(v => (
                    <MenuItem key={v} value={v}>
                      {t("generic.country." + v)}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {t("auth.start.residence-description")}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LegalCompliance />
          </Grid>
          <Grid item xs={6}>
            {cancelable && shareTokenOpt && (
              <Button
                disabled={loading}
                type="button"
                variant="contained"
                color="secondary"
                fullWidth
                data-testid="input-cancel"
                className={classes.submit}
                onClick={handleBack}
              >
                <FaArrowLeft />
                &nbsp;&nbsp;{t("generic.word.back")}
              </Button>
            )}
          </Grid>
          <Grid item xs={6}>
            <Button
              disabled={loading}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              data-testid="input-submit"
              className={classes.submit}
            >
              {t("generic.word.next")}&nbsp;&nbsp;
              <FaArrowRight />
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};
