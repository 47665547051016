import axios from "axios";
import { apiURL } from "../config";
import { defaultErrorBehaviour } from "../generics/errors/actions";
export const LOADING_CONTEXT = "identity";
export const LOADING_CONTEXT_BACKGROUND = "identityBG";

export const ACTION_SET_AUTH_TOKEN = "ACTION_SET_AUTH_TOKEN";
export const ACTION_SET_OWN_IDENTITY = "ACTION_SET_OWN_IDENTITY";
export const ACTION_RESET_AUTHENTICATION = "ACTION_RESET_AUTHENTICATION";
export const ACTION_FLAG_AUTHENTICATED_IN_SESSION =
  "ACTION_FLAG_AUTHENTICATED_IN_SESSION";
export const ACTION_RESET_STORE_FROM_AUTH = "RESET_STORE";

export const LS_TOKEN_KEY = "b2jjwt";

// setAuthToken invoked by fireAuthenticated when authentication completed
export const setAuthToken = token => {
  return {
    type: ACTION_SET_AUTH_TOKEN,
    token: token
  };
};

export const flagAuthenticatedInSession = () => ({
  type: ACTION_FLAG_AUTHENTICATED_IN_SESSION
});

// setOwnIdentity in redux store 🚀
export const setOwnIdentity = identity => {
  return {
    type: ACTION_SET_OWN_IDENTITY,
    identity: identity
  };
};

// resetAuthentication in store
export const resetAuthentication = () => {
  return {
    type: ACTION_RESET_AUTHENTICATION
  };
};
// fireResetAuthentication dispatches resetAuthentication and removes state from browser
export const fireResetAuthentication = () => {
  return async dispatch => {
    // reset stuff
    axios.defaults.headers.common["Authorization"] = "";

    // completely clear localstorage and session storage
    localStorage.clear();
    sessionStorage.clear();

    dispatch(resetAuthentication());
    dispatch({
      type: ACTION_RESET_STORE_FROM_AUTH
    });
  };
};

// fireAuthenticated async action that will cause token to be ...
// * stored in local storage
// * injected into axios headers
// * stored in redux state
export const fireAuthenticated = token => {
  return async dispatch => {
    axios.defaults.headers.common["Authorization"] = `${token}`;
    localStorage.setItem(LS_TOKEN_KEY, token);

    dispatch(setAuthToken(token));
    dispatch(fireReqOwnIdentity());
  };
};

// fireRestorationNeeded when app launched.
// will cause authentication to be restored from localstorage or whatever
export const fireRestoration = () => {
  return async dispatch => {
    const storedToken = localStorage.getItem(LS_TOKEN_KEY);
    if (!storedToken) {
      return;
    }

    try {
      // refresh token. this will refresh and validate in one
      const res = await axios.put(
        apiURL("/auth"),
        {},
        {
          headers: { Authorization: storedToken }
        }
      );
      dispatch(fireAuthenticated(res.data.token));
    } catch (error) {
      // failed refreshing the token. forget about stored authentication
      dispatch(fireResetAuthentication());
    }
  };
};

// fireReqOwnIdentity will asynchronoously fetch own identity details
export const fireReqOwnIdentity = () => {
  return async dispatch => {
    const url = apiURL("/identity/-");
    try {
      // request information about our self
      const selfRes = await axios.get(url);
      const identity = selfRes.data;

      dispatch(setOwnIdentity(identity));
    } catch (error) {
      dispatch(defaultErrorBehaviour(error));
      dispatch(fireResetAuthentication());
    }
  };
};
