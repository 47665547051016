import React from "react";
import { PrivateRoute } from "../auth/components/private-route";

import { IdentityDetailsPage } from "./pages";

export const IdentityRoutes = () => {
  return (
    <>
      <PrivateRoute path="/identity" exact component={IdentityDetailsPage} />
    </>
  );
};
