import { Chip, Tooltip } from "@material-ui/core";
import React from "react";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { useTranslation } from "react-i18next";

export const FindersFeeChip = ({ job }) => {
  const { t } = useTranslation();

  return (
    <>
      {job.findersFee > 0 && (
        <Tooltip
          title={t("power-pro-shared.findersfee-chip.finders-fee-tooltip")}
        >
          <Chip
            variant="outlined"
            icon={<MonetizationOnIcon />}
            label={`${job.findersFee} ${job.findersFeeCurrency}`}
            color="primary"
          />
        </Tooltip>
      )}
    </>
  );
};
