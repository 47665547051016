import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(2)
  },
  signupAlert: {
    marginTop: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(1, 0, 2)
  },
  altSubmit: {
    margin: theme.spacing(0, 0, 2)
  },
  appBar: {
    position: "relative"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));
