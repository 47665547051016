import Fuse from "fuse.js";
import moment from "moment";
import { createSelector } from "reselect";
import { SORT_DEADLINE, SORT_TITLE } from "./actions";

// FIXME: adopt to new JCA structure
export const selectJCAByToken = token => {
  return createSelector(
    state => state.domain.jcas,
    jcas => {
      const result = jcas.find(
        jca =>
          jca.association.sourceToken?.token === token ||
          jca.association.sourceToken?.originalToken === token ||
          jca.association.customToken.token === token
      );
      console.log(`selectJCAByToken finding ${token} in jcas`, jcas, result);
      return result;
    }
  );
};

export const selectJCAByJobId = jobId => {
  return createSelector(
    state => state.domain.jcas,
    jcas => jcas.find(jca => jca.job.id === jobId)
  );
};

export const selectJCAs = () => {
  return createSelector(
    state => state.domain.jcas,
    jcas => jcas
  );
};

export const selectFilteredJCAs = () => {
  return createSelector(
    state => ({ jcas: state.domain.jcas, filter: state.domain.jcasFilter }),
    ipt => {
      // clone array, otherwise we will work on concurrent references
      let jcas = ipt.jcas.filter(
        jca => false === jca.association.node.archived
      );

      // apply company filter
      if (ipt.filter.companyId > -1) {
        jcas = jcas.filter(jca => jca.company.id === ipt.filter.companyId);
      }

      // apply text search using fuse.js
      if (ipt.filter.textSearch !== "") {
        // filter by text search
        const fuse = new Fuse(jcas, {
          keys: ["job.title"],
          includeScore: true,
          includeMatches: true,
          threshold: 0.5
        });

        const result = fuse.search(ipt.filter.textSearch);
        console.log("selectJCAsFiltered | fuse.search => ", result);
        jcas = result.map(x => x.item);
      }

      // apply sorting
      switch (ipt.filter.sorting) {
        case SORT_DEADLINE:
          jcas = jcas.sort((a, b) =>
            moment(a.job.deadline).isBefore(moment(b.job.deadline)) ? -1 : 1
          );
          console.log("selectJCAsFiltered | SORT_DEADLINE", jcas);
          break;
        default:
        case SORT_TITLE:
          jcas = jcas.sort((a, b) => a.job.title.localeCompare(b.job.title));
          console.log("selectJCAsFiltered | SORT_TITLE", jcas);
          break;
      }

      return jcas;
    }
  );
};

export const selectJCAsFilter = () => {
  return createSelector(
    state => state.domain.jcasFilter,
    filter => filter
  );
};

// FIXME: resurrect
// export const selectAllJobs = () => {
//   return createSelector(
//     state => state.domain.jobs,
//     jobs => jobs
//   );
// };
//
// export const selectAllActiveJobs = (includeArchived = false) => {
//   return createSelector(
//     state => ({ jobs: state.domain.jobs, rtmem: state.domain.rtmem }),
//     ({ jobs, rtmem }) =>
//       // job  archived => never shown!
//       // otherwise
//       // job is not past deadline
//       // OR
//       // application to job exists
//       {
//         return jobs.filter(job => {
//           const isArchived =
//             rtmem.findIndex(
//               rt => rt.jobId === job.id && rt.archived === true
//             ) !== -1;
//
//           const deadlineIsInFuture = moment(job.deadline).isAfter(moment());
//
//           const jobApplicationRunning =
//             rtmem.findIndex(
//               rt => rt.jobId === job.id && rt.status === RTMEM_STATUS_APPLIED
//             ) > -1;
//
//           return isArchived === true && includeArchived === false
//             ? false
//             : deadlineIsInFuture || jobApplicationRunning;
//         });
//       }
//   );
// };
//
// export const hasAppliedForJob = job => {
//   return createSelector(
//     state => state.domain.rtmem,
//     rtmem =>
//       rtmem.findIndex(
//         rt => rt.jobId === job.id && rt.status === RTMEM_STATUS_APPLIED
//       ) >= 0
//   );
// };
//
// export const isAnyArchived = () => {
//   return createSelector(
//     state => state.domain.rtmem,
//     rtmem => rtmem[0] && rtmem.findIndex(rt => rt.archived === true) >= 0
//   );
// };
//
// export const isJobArchived = jobId => {
//   return createSelector(
//     state => state.domain.rtmem,
//     rtmem =>
//       rtmem.findIndex(rt => rt.jobId === jobId && rt.archived === true) >= 0
//   );
// };
