import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaInfoCircle } from "react-icons/fa";
import { CardParagraph } from "../../generics/components/card-paragraph";
import { CardText } from "../../generics/components/card-text";
import { FindersFeeExplanationModal } from "./finders-fee-explanation-modal";

export const FindersFeePanel = ({ jca }) => {
  const [
    openFindersFeeExplanationModal,
    setOpenFindersFeeExplanationModal
  ] = useState(false);

  const handleFindersFeeExplanationModalClose = () =>
    setOpenFindersFeeExplanationModal(false);
  const { t } = useTranslation();

  const hasAltText = useMemo(
    () => jca.job && ("" + jca.job.findersFeeAltText).length !== 0,
    [jca]
  );

  return (
    <>
      <FindersFeeExplanationModal
        open={openFindersFeeExplanationModal}
        onClose={handleFindersFeeExplanationModalClose}
        jca={jca}
      />

      <CardParagraph>
        <Box pb={1}>
          {hasAltText ? (
            <CardText>{jca.job.findersFeeAltText}</CardText>
          ) : (
            <>
              <CardText>
                {t("referal.findersfee-panel.pre-findersFee", {
                  jca
                })}
              </CardText>

              <Typography
                variant="h6"
                color="primary"
                style={{ cursor: "pointer" }}
                onClick={() => setOpenFindersFeeExplanationModal(true)}
              >
                <>
                  {" "}
                  {t("referal.findersfee-panel.findersFee", {
                    jca
                  })}
                  &nbsp;
                  <FaInfoCircle />
                </>
              </Typography>
            </>
          ) /* hasAltText */}
        </Box>
      </CardParagraph>
    </>
  );
};
