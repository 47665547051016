import { Typography } from "@material-ui/core";
import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { CardText } from "../../generics/components/card-text";
import { ReferalCardGrid } from "../components/referal-card-grid";
import { ReferalCardGridItem } from "../components/referal-card-item";
import { ReferrerOrProspectContactConfirmCard } from "../components/referrer-or-prospect-contact-confirm-card";
import { ReferrerOrProspectJobDetailsCard } from "../components/referrer-or-prospect-job-details-card";
import { AuthWrapper } from "./auth-wrapper";

const AuthStartHeader = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h5">
        {t("referal.referrer-or-prospect-contact-auth.card-header.header")}
      </Typography>
      <CardText>
        {t("referal.auth-wizard-start-header.contact-request")}
      </CardText>
    </>
  );
};

const AuthDetailsHeader = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h5">
        {t("referal.referrer-or-prospect-contact-auth.card-header.header")}
      </Typography>
      <CardText>{t("referal.auth-wizard-details-header.body")}</CardText>
    </>
  );
};

const InnerPage = ({ jca }) => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>
          {t("pages.expected-referrer-or-prospect-scenario.title", {
            jca: jca
          })}
        </title>
      </Helmet>

      <ReferalCardGrid>
        <ReferalCardGridItem>
          <ReferrerOrProspectContactConfirmCard jca={jca} />
        </ReferalCardGridItem>

        <ReferalCardGridItem>
          <ReferrerOrProspectJobDetailsCard jca={jca} />
        </ReferalCardGridItem>
      </ReferalCardGrid>
    </>
  );
};

export const ApplyPage = () => {
  return (
    <AuthWrapper
      wrap={InnerPage}
      startHeader={<AuthStartHeader />}
      detailsHeader={<AuthDetailsHeader />}
      detailsRequired={true}
    ></AuthWrapper>
  );
};
