import {
  CardHeader,
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  Menu,
  MenuItem
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import clsx from "clsx";
import { useConfirm } from "material-ui-confirm";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { FaChevronDown, FaShare, FaTrash } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import { useDispatch } from "react-redux";
import { Element, scroller } from "react-scroll";
import { fireArchiveJCA } from "../../../domain/actions";
import { FindersFeeChip } from "../../../power-pro-shared/findersfee-chip";
import { PUIShareDialog } from "../pui-share-dialog";

const useStyles = makeStyles(theme => ({
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  share: {
    marginLeft: "auto",
    color: theme.palette.primary.main
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  }
}));

const PUIJobCard = ({ jca, state, onNext }) => {
  const { t } = useTranslation();
  const confirm = useConfirm();
  const dispatch = useDispatch();

  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const [expanded, setExpanded] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [cardMenuAnchorEl, setCardMenuAnchorEl] = useState(null);

  const formattedDeadline = useMemo(
    () => moment(jca.job.deadline).format(t("global.dateFormat.short")),
    [jca, t]
  );

  const scrollerElementName = useMemo(() => `pui-job-card-${jca.job.id}`, [
    jca
  ]);

  const handleCollapse = () => {
    setExpanded(false);

    if (isMobile) {
      scroller.scrollTo(scrollerElementName, {
        duration: 200,
        delay: 0,
        smooth: "easeInOutQuart"
      });
    }
  };

  const handleShareDialogClose = () => {
    setShowShareDialog(false);
  };

  const handleCardMenuClick = event => {
    setCardMenuAnchorEl(event.currentTarget);
  };

  const handleArchiveElement = () => {
    setCardMenuAnchorEl(null);

    confirm({
      description: t("power-ui.pui-job-card.confirm-delete", { jca: jca })
    }).then(() => dispatch(fireArchiveJCA(jca)));
  };

  const handleShowShareDialog = () => {
    setCardMenuAnchorEl(null);
    setShowShareDialog(true);
  };

  return (
    <Element name={scrollerElementName}>
      <Menu
        anchorEl={cardMenuAnchorEl}
        keepMounted
        open={Boolean(cardMenuAnchorEl)}
        onClose={() => setCardMenuAnchorEl(null)}
      >
        <MenuItem onClick={handleShowShareDialog}>
          <FaShare />
          &nbsp;&nbsp;Teilen
        </MenuItem>

        <MenuItem onClick={handleArchiveElement}>
          <FaTrash />
          &nbsp;&nbsp;Entfernen
        </MenuItem>
      </Menu>

      <PUIShareDialog
        jca={jca}
        open={showShareDialog}
        onClose={handleShareDialogClose}
      />
      <Card>
        <CardHeader
          title={
            <>
              <Typography variant="caption">
                {jca.company.companyName}
              </Typography>
              <Typography variant="h5">
                {t(
                  "referal.employee-onboarding-job-details-card.card-header.header",
                  { job: jca.job }
                )}
              </Typography>
            </>
          }
          action={
            <IconButton aria-label="settings" onClick={handleCardMenuClick}>
              <MoreVertIcon />
            </IconButton>
          }
          subheader={
            <Typography component="span">
              {jca.job.subTitle}
              {bull}
              {jca.job.location}
              {bull}
              {jca.job.type}
              {bull}
              {t("power-ui.pui-job-card.subheader-job-active-until", {
                deadline: formattedDeadline
              })}
            </Typography>
          }
        />

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <ReactMarkdown source={jca.job.description} escapeHtml={false} />

            {/* COMPANY TITLE AND DESCRIPTION */}
            <Typography variant="h6">{jca.company.companyName}</Typography>
            <ReactMarkdown>{jca.company.description}</ReactMarkdown>
          </CardContent>
        </Collapse>

        <CardActions disableSpacing>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded
            })}
            aria-label="show details"
            onClick={() =>
              expanded === true ? handleCollapse() : setExpanded(true)
            }
          >
            <FaChevronDown />
          </IconButton>

          <Grid container spacing={1} direction="row">
            <Grid item>
              <FindersFeeChip job={jca.job} />
            </Grid>
          </Grid>

          <IconButton
            className={classes.share}
            aria-label="hide details"
            onClick={handleShowShareDialog}
          >
            <FaShare />
          </IconButton>
        </CardActions>
      </Card>
    </Element>
  );
};

export default PUIJobCard;
