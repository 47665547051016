import { Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { useState } from "react";
import { FaRegStar, FaShare } from "react-icons/fa";
import { Link } from "react-router-dom";

const MarketplaceInfoAlert = () => {
  const lsKey = "MarketplaceInfoAlert_hideInfoAlert";

  const [showAlert, setShowAlert] = useState(!localStorage.getItem(lsKey));

  const hideInfoAlert = e => {
    e.preventDefault();
    localStorage.setItem(lsKey, true);
    setShowAlert(false);
  };

  return (
    <>
      {showAlert && (
        <Alert severity="info">
          <span>
            Herzlich Willkommen zum <strong>Block2Job Marktplatz</strong>. Hier
            finden Sie ausgewählte Vakanzen, die Sie jederzeit in Ihrem Netzwerk
            empfehlen können: ein Klick auf <FaRegStar /> genügt. Über{" "}
            <FaShare /> können die Vakanzen anschließend geteilt werden. Die{" "}
            <strong>
              &quot;
              <FaRegStar /> Merkliste&quot;
            </strong>{" "}
            im Menü oben erlaubt eine Auflistung aller von Ihnen markierten
            stellen.
          </span>
          <Box mt={2}>
            <Link to="#" onClick={hideInfoAlert} variant="body1">
              {"Hinweis ausblenden"}
            </Link>
          </Box>
        </Alert>
      )}
    </>
  );
};

export default MarketplaceInfoAlert;
