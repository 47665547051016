import { Box, CircularProgress } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { ProJobCard } from "../pro-job-card";

const ProJobList = ({ jcs }) => {
  const increment = 5;
  const [itemCount, setItemCount] = useState(increment);

  const items = useMemo(() => {
    if (!jcs) {
      return [<Box key={0}></Box>];
    }

    return jcs
      .slice(0, jcs.length <= itemCount ? jcs.length : itemCount)
      .map((jc, i) => (
        <Box pb={2} key={"" + jc.job.id}>
          <ProJobCard jc={jc} />
        </Box>
      ));
  }, [jcs, itemCount]);

  return (
    <InfiniteScroll
      pageStart={0}
      hasMore={jcs && itemCount < jcs.length}
      loader={<CircularProgress key={-1} />}
      loadMore={() => setItemCount(itemCount + increment)}
    >
      {items}
    </InfiniteScroll>
  );
};

export default ProJobList;
