import {
  CardHeader,
  Chip,
  Collapse,
  Grid,
  IconButton,
  makeStyles
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import StarIcon from "@material-ui/icons/Star";
import clsx from "clsx";
import moment from "moment";
import React, { useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { FaChevronDown, FaRegStar, FaShare } from "react-icons/fa";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { Element, scroller } from "react-scroll";
import { selectJCAByJobId } from "../../../domain/selectors";
import { FindersFeeChip } from "../../../power-pro-shared/findersfee-chip";
import { PUIShareDialog } from "../../../power-ui/components/pui-share-dialog";
import { fireJoinRetreeByID } from "../../actions";

const useStyles = makeStyles(theme => ({
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)"
  },
  share: {
    marginLeft: "auto",
    color: theme.palette.primary.main
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  }
}));

const ProJobCard = ({ jc }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const [expanded, setExpanded] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const jca = useSelector(selectJCAByJobId(jc.job.id));

  const formattedDeadline = useMemo(
    () => moment(jc.job.deadline).format(t("global.dateFormat.short")),
    [jc, t]
  );

  const scrollerElementName = useMemo(() => `pui-job-card-${jc.job.id}`, [jc]);

  const handleCollapse = () => {
    setExpanded(false);

    if (isMobile) {
      scroller.scrollTo(scrollerElementName, {
        duration: 200,
        delay: 0,
        smooth: "easeInOutQuart"
      });
    }
  };

  const handleJoinReTree = () => {
    dispatch(fireJoinRetreeByID(jc.job.id));
  };

  const handleShareDialogClose = () => {
    setShowShareDialog(false);
  };

  if (jca && jca.association && jca.association.node.archived === true) {
    console.log("ProJobCard hiding archived", jca);
    return <></>;
  }

  return (
    <Element name={scrollerElementName}>
      <PUIShareDialog
        jca={jca}
        open={showShareDialog}
        onClose={handleShareDialogClose}
      />

      <Card>
        <CardHeader
          title={
            <>
              <Typography variant="caption">
                {jc.company.companyName}
              </Typography>
              <Typography variant="h5">
                {t(
                  "referal.employee-onboarding-job-details-card.card-header.header",
                  { job: jc.job }
                )}
              </Typography>
            </>
          }
          subheader={
            <Typography component="span">
              {jc.job.subTitle}
              {bull}
              {jc.job.location}
              {bull}
              {jc.job.type}
              {bull}
              {t("power-ui.pui-job-card.subheader-job-active-until", {
                deadline: formattedDeadline
              })}
            </Typography>
          }
        />

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <ReactMarkdown source={jc.job.description} />

            {/* COMPANY TITLE AND DESCRIPTION */}
            <Typography variant="h6">{jc.company.companyName}</Typography>
            <ReactMarkdown>{jc.company.description}</ReactMarkdown>
          </CardContent>
        </Collapse>

        <CardActions disableSpacing>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded
            })}
            aria-label="show details"
            onClick={() =>
              expanded === true ? handleCollapse() : setExpanded(true)
            }
          >
            <FaChevronDown />
          </IconButton>

          <Grid container spacing={1} direction="row">
            <Grid item>
              <FindersFeeChip job={jc.job} />
            </Grid>
            {!!jca && (
              <Grid item>
                <Chip
                  variant="outlined"
                  icon={<StarIcon />}
                  label={t("pro-ui.pro-job-card.watch-list-label")}
                  color="secondary"
                />
              </Grid>
            )}
          </Grid>

          {!jca && (
            <IconButton
              onClick={handleJoinReTree}
              className={classes.share}
              aria-label="hide details"
            >
              <FaRegStar />
            </IconButton>
          )}

          {jca && (
            <IconButton
              onClick={() => setShowShareDialog(true)}
              className={classes.share}
              aria-label="hide details"
            >
              <FaShare />
            </IconButton>
          )}
        </CardActions>
      </Card>
    </Element>
  );
};

export default ProJobCard;
