import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { apiURL } from "../../../config";
import { defaultErrorBehaviour } from "../../../generics/errors/actions";
import * as validators from "../../../generics/validators";
import * as actions from "../../actions";
import { currentIdentity } from "../../selectors";
import { useStyles } from "../styles";
import { STATE_FINISHED } from "./constants";
import { StateLegalLinks } from "./state-legal-links";
import { WizardTitle } from "./wizard-title";

export const StateDetails = ({
  transitState,
  customDetailsStateHeader,
  requireLastName = true,
  detailsRequired = true
}) => {
  // DTO for registration
  const initialDTO = {
    firstName: "",
    lastName: ""
  };

  const [dto, setDTO] = useState(initialDTO);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(
    validators.deriveNoErrorsObj(initialDTO)
  );
  const classes = useStyles();
  const { t } = useTranslation();
  const ownIdentity = useSelector(currentIdentity());
  const dispatch = useDispatch();

  // following will immediately transit to state finished if
  // there's already firstName and lastName defined
  useEffect(() => {
    if (ownIdentity) {
      if (!detailsRequired || ownIdentity.detailsComplete === true) {
        transitState(STATE_FINISHED);
      }
    }
  }, [ownIdentity, detailsRequired, requireLastName, transitState]);

  // submit form and process result
  const submit = async () => {
    // everything valid - create identity!
    try {
      setLoading(true);
      await axios.patch(apiURL("/identity/-/details"), dto);

      // update own details nw
      dispatch(actions.fireReqOwnIdentity());
      // continue to finished state
      transitState(STATE_FINISHED);
    } catch (error) {
      dispatch(defaultErrorBehaviour(error));
    } finally {
      setLoading(false);
    }
  };

  // handleForm validation etc. calls submit when there are no errors
  const handleForm = async event => {
    event.preventDefault();
    event.stopPropagation();

    const errs = {
      firstName: validators.isEmpty(dto.firstName),
      lastName: requireLastName && validators.isEmpty(dto.lastName)
    };

    console.log(errs);

    setErrors(errs);

    if (validators.hasNoError(errs)) {
      submit();
    }
  };

  return (
    <>
      {ownIdentity && (
        <div data-testid="state-details">
          {!!customDetailsStateHeader && customDetailsStateHeader}
          {!customDetailsStateHeader && (
            <>
              <WizardTitle
                title={t("auth.details.title")}
                text={t("auth.details.subTitle")}
              />
            </>
          )}

          <form className={classes.form} onSubmit={handleForm} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={errors.firstName}
                  disabled={loading}
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label={t("generic.word.first-name")}
                  name="firstName"
                  autoComplete="firstName"
                  inputProps={{
                    "data-testid": "input-firstName"
                  }}
                  helperText={
                    errors.firstName && t("generic.validation.first-name")
                  }
                  onChange={e => setDTO({ ...dto, firstName: e.target.value })}
                />
              </Grid>

              {requireLastName && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    error={errors.lastName}
                    disabled={loading}
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label={t("generic.word.last-name")}
                    name="lastName"
                    autoComplete="lastName"
                    inputProps={{
                      "data-testid": "input-lastName"
                    }}
                    helperText={
                      errors.lastName && t("generic.validation.last-name")
                    }
                    onChange={e => setDTO({ ...dto, lastName: e.target.value })}
                  />
                </Grid>
              )}
            </Grid>
            <Button
              disabled={loading}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              data-testid="input-submit"
              className={classes.submit}
            >
              {t("generic.word.next")}
            </Button>
          </form>

          <StateLegalLinks />
        </div>
      )}

      {!ownIdentity && (
        <div data-testid="waiting-for-self">
          <CircularProgress color="secondary" />
        </div>
      )}
    </>
  );
};
