import { Box, Container, CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import i18n from "i18next";
import { ConfirmProvider } from "material-ui-confirm";
import "polyfill-array-includes";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { initReactI18next, useTranslation } from "react-i18next";
import { Provider, useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { fireRestoration } from "./auth/actions";
import config from "./config";
import { Copyright } from "./generics/components";
import Alert from "./generics/errors/components";
import { Flash } from "./generics/flash";
import HomeRoute from "./home/routes";
import de from "./i18n/locales/de.json";
import demanual from "./i18n/de-manual.json";
import en from "./i18n/locales/en.json";
import enmanual from "./i18n/en-manual.json";
import { determineLanguage, selectLanguage } from "./i18n/redux";
import { IdentityRoutes } from "./identity/routes";
import { AuthRoutes } from "./routes/auth";
import { JobRoutes } from "./routes/job";
import { ProRoutes } from "./routes/pro";
import { PUIRoutes } from "./routes/pui";
import { OrderRoutes } from "./routes/order";
import store from "./store";
import B2JTheme from "./theme";
import merge from "deepmerge";

//i18n setup
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      de: { translation: merge(de, demanual) },
      en: { translation: merge(en, enmanual) }
    },
    fallbackLng: config.fallbackLanguage,
    lng: config.defaultLanguage,
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: (value, rawFormat, lng) => {
        const [format /*, ...additionalValues*/] = rawFormat
          .split(",")
          .map(v => v.trim());

        const fractionDigits = currency => {
          switch (currency) {
            case "BTC":
            case "ETH":
            case "ADA":
              return 8;
            default:
              return 2;
          }
        };
        switch (format) {
          case "findersFee":
            return Intl.NumberFormat(lng, {
              style: "currency",
              currency: value.findersFeeCurrency,
              maximumFractionDigits: fractionDigits(value.findersFeeCurrency)
            }).format(value.findersFee);

          case "price":
            return Intl.NumberFormat(lng, {
              style: "currency",
              currency: value.currency,
              maximumFractionDigits: fractionDigits(value.currency)
            }).format(value.price);
          default:
            return value;
        }
      }
    }
  });

const B2J = () => {
  const language = useSelector(selectLanguage());
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // ensure aggreated data is available, i.e. our initial payload
  useEffect(() => {
    dispatch(fireRestoration());
  }, [dispatch]);

  // ensure language always termined
  useEffect(() => {
    if (!language.determined) {
      dispatch(determineLanguage());
    }
  }, [dispatch, language]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: language.current }} />
      <ThemeProvider theme={B2JTheme}>
        <ConfirmProvider
          defaultOptions={{
            title: t("confirm-provider.title"),
            confirmationText: t("confirm-provider.confirmation-text"),
            cancellationText: t("confirm-provider.cancellation-text")
          }}
        >
          <BrowserRouter>
            <CssBaseline />
            <Container component="main" maxWidth="sm">
              <Box mt={3} mb={3}>
                <Alert />
                <Flash />
                <HomeRoute />
                <AuthRoutes />
                <IdentityRoutes />

                {/* JobRoutes needs to be the very last routes declared, since they implement some catch-all logic */}
                <JobRoutes />
                <OrderRoutes />
                <PUIRoutes />
                <ProRoutes />
                <Copyright />
              </Box>
            </Container>
          </BrowserRouter>
        </ConfirmProvider>
      </ThemeProvider>
    </>
  );
};

const RootPage = () => {
  return (
    <Provider store={store}>
      <B2J />
    </Provider>
  );
};

export default RootPage;
