import { Avatar, CardHeader } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { default as React, useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FaThumbsUp } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { apiURL } from "../../../config";
import { CardText } from "../../../generics/components/card-text";
import { defaultErrorBehaviour } from "../../../generics/errors/actions";
import { useStyles } from "../../../theme/classes";

const ReferrerOrProspectContactFeedbackCard = ({ jca, param }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const contentKey = useMemo(
    () =>
      "referal.referrer-or-prospect-contact-feedback-card.auth.card-header.card-content.appl-feedback." +
      param,
    [param]
  );

  const submitFeedback = useCallback(async () => {
    if (!(jca.association.customToken && jca.association.node)) {
      console.log(
        "ReferrerOrProspectContactFeedbackCard#submitFeedback | missing customToken or Node => return",
        jca
      );
      return;
    }

    try {
      await axios.patch(
        apiURL(
          `/rt-jv/${jca.association.customToken.token}/application/feedback`
        ),
        {
          response: param
        }
      );
    } catch (e) {
      console.log(
        "ReferrerOrProspectContactFeedbackCard#submitFeedback => ",
        e
      );

      const action = defaultErrorBehaviour(e, "auth.validateEmail.error");
      if (423 !== action.status) {
        // <=> 423 LOCKED Feedback provided too often. Ignore this one!
        dispatch(action);
      }
    }
  }, [jca, dispatch, param]);

  useEffect(() => {
    submitFeedback();
  }, [jca, submitFeedback]);

  const styles = useStyles();

  return (
    <Card>
      <>
        <CardHeader
          avatar={
            <Avatar className={styles.focusAvatar} aria-label="recipe">
              <FaThumbsUp />
            </Avatar>
          }
          title={
            <Typography variant="h5">
              {t(
                "referal.referrer-or-prospect-contact-feedback-card.auth.card-header.card-header.header"
              )}
            </Typography>
          }
        />
        <CardContent>
          <CardText>{t(contentKey)}</CardText>
        </CardContent>
      </>
    </Card>
  );
};

export default ReferrerOrProspectContactFeedbackCard;
