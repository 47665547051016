import moment from "moment";
import { createSelector } from "reselect";

export const selectJCs = () => {
  return createSelector(
    state => state.pro.jcs,
    jcs =>
      jcs.sort((a, b) =>
        moment(b.job.createdAt).isBefore(moment(a.job.createdAt)) ? -1 : 1
      )
  );
};
