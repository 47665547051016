import { createSelector } from "reselect";

export const ACTION_STORE_UI_SETTING = "ACTION_STORE_UI_SETTING";

export const storeUISetting = (key, value) => {
  return {
    type: ACTION_STORE_UI_SETTING,
    payload: { key: key, value: value }
  };
};

export const selectUISetting = (key, defaultValue) => {
  return createSelector(
    state => state.ui,
    settings => {
      let setting = settings.find(setting => setting.key === key);
      if (setting) {
        return setting.value;
      }

      return defaultValue;
    }
  );
};

const initialState = [];

export function uiSettingsReducer(state = initialState, action) {
  if (action.type === ACTION_STORE_UI_SETTING) {
    return [...state.filter(v => v.key !== action.payload.key), action.payload];
  } else {
    return state;
  }
}
