import React from "react";
import { Route } from "react-router-dom";
import { ContainerDetailsPage } from "../order/container-details-page";
import { ProviderDetailsPage } from "../order/provider-details-page";
import { OrderContextWrapper } from "../order/state";

export const OrderRoutes = () => {
  return (
    <>
      <OrderContextWrapper>
        <Route path="/order" exact component={ContainerDetailsPage} />
        <Route path="/order/provider" exact component={ProviderDetailsPage} />
      </OrderContextWrapper>
    </>
  );
};
