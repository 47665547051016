import { Box, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AuthLevel1 } from "../../auth/const";
import { isAuthenticated } from "../../auth/selectors";
import { CardText } from "../../generics/components/card-text";
import { flashMessage } from "../../generics/flash";
import { AuthWrapper } from "./auth-wrapper";
import { ReferralStartPage } from "./start-page";

const AuthHeader = () => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h6">
        {t("referal.referrer-or-prospect-reminder-auth.caption")}
      </Typography>

      <Box pt={2}>
        <CardText>
          {t("referal.referrer-or-prospect-reminder-auth.text")}
        </CardText>
      </Box>
    </>
  );
};

export const ActivateReminderPage = props => {
  const dispatch = useDispatch();

  const authenticated = useSelector(isAuthenticated(AuthLevel1));

  useEffect(() => {
    if (authenticated === true) {
      dispatch(
        flashMessage(
          "referal.referrer-or-prospect-reminder-auth.reminder-activated",
          "info"
        )
      );
    }
  }, [dispatch, authenticated]);

  return (
    <AuthWrapper
      wrap={ReferralStartPage}
      startHeader={<AuthHeader />}
      {...props}
    ></AuthWrapper>
  );
};
