import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import { CenteredInCard } from "../../../generics/components/centered-in-card";
export const ShareButton = props => {
  return (
    <Grid item>
      <CenteredInCard>
        <Button
          color="primary"
          variant="contained"
          size={props.size}
          onClick={() => props.handler(props?.medium)}
        >
          {props.children}
          &nbsp;{props.text}
        </Button>
      </CenteredInCard>
    </Grid>
  );
};
